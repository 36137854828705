export class ConvinienceFee {
  creditCard: number;
  debitCard: number;
  ach: number;
  constructor() {
    this.creditCard = null;
    this.debitCard = null;
    this.ach = null; 
  }
}
