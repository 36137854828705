import { ConvinienceFee } from './ConvinienceFee.model';

export class Proxynization {
  requestType: string;
  amount: number;
  accountType: string;
  transactionIndustryType: string;
  holderType: string;
  holderName: string;
  accountNumber: string;
  accountAccessory: string;
  merchantId: string;
  utilityId: string;
  consumerId: string;
  billId: string;
  email: string;
  month: string;
  year: string;
  convenienceFee: ConvinienceFee;
  totalPay: number;
  // street: string;
  // city: string;
  // state: string;

  constructor() {
    this.requestType = null;
    this.amount = null;
    this.accountType = null;
    this.transactionIndustryType = null;
    this.holderType = null;
    this.holderName = null;
    this.accountNumber = null;
    this.accountAccessory = null;
    this.merchantId = null;
    this.utilityId = null;
    this.consumerId = null;
    this.billId = null;
    this.email = null;
    this.month = null;
    this.year = null;
    this.convenienceFee =  new ConvinienceFee();
    this.totalPay = null;
  }
}
