import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { PrivacyPalicyComponent } from '../privacy-palicy/privacy-palicy.component';
declare var $: any;


@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',

})
export class TermsAndConditionsComponent implements OnInit {

  constructor(private router: Router,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit() {
    $('.height-terms-condition').each(function() {
			var myHeight = $(window).height() - 190;
			$(this).slimscroll({
				height: myHeight
			});
    });
  }

  viewPrivacyPolicy() {
    this.bsModalRef = this.modalService.show(PrivacyPalicyComponent, {
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }
}
