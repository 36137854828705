import { Time } from '@angular/common';

export class PaymentDate {
    public Date: string;
    public Time: string;
    public TimeZone: string;

    constructor()
    {
        this.Date = null;
        this.Time = null;
        this.TimeZone = null;
    }
}
