export class BillViewModel {
  public id: string;
  public email: string;
  public billUserName: string;
  public companyLogo: string;
  public importantMessage: string;
  public accountNumber: string;
  public billNumber: string;
  public serviceAddress: string;
  public serviceTo: string;
  public serviceFrom: string;
  public amount: string;
  public numberOfDays: string;
  public locationNumber: string;
  public dateBillMailed: string;
  public dueDate: string;
  public meterSerialNumber: string;
  public meterSerialNumber1: string;
  public gallonUsed: string;
  public gallonUsed1: string;
  public prReading: string;
  public prReading1: string;
  public currentRead: string;
  public currentRead1: string;
  public totalDueNow: string;
  public service: string;
  public address1: string;
  public address2: string;
  public address3: string;
  public address4: string;
  public previousBalance: string;
  public otherData1: string;
  public otherData2: string;
  public otherData3: string;
  public otherData4: string;
  public otherData5: string;
  public otherData6: string;
  public otherData7: string;
  public otherData8: string;
  public otherData9: string;
  public otherData10: string;
  public otherData11: string;
  public otherData12: string;
  public otherData13: string;
  public otherData14: string;
  public published: string;
  public afterDueDate: string;
  public billDate: string;
  public currentCharge: string;
  public percentageBalance: string;
  public utilityId: string;
  public utilityName: string;
  public merchantId: string;
  public merchantLogo: string;
  public consumerId: string;
  public templateId: string;
  public graphList: any;

  constructor() {
    this.id = null;
    this.email = null;
    this.billUserName = null;
    this.companyLogo = null;
    this.importantMessage = null;
    this.accountNumber = null;
    this.accountNumber = null;
    this.billNumber = null;
    this.serviceAddress = null;
    this.serviceTo = null;
    this.serviceFrom = null;
    this.amount = null;
    this.numberOfDays = null;
    this.locationNumber = null;
    this.dateBillMailed = null;
    this.dueDate = null;
    this.meterSerialNumber = null;
    this.meterSerialNumber1 = null;
    this.gallonUsed = null;
    this.gallonUsed1 = null;
    this.prReading = null;
    this.prReading1 = null;
    this.currentRead = null;
    this.currentRead1 = null;
    this.totalDueNow = null;
    this.service = null;
    this.address1 = null;
    this.address2 = null;
    this.address3 = null;
    this.address4 = null;
    this.previousBalance = null;
    this.otherData1 = null;
    this.otherData2 = null;
    this.otherData3 = null;
    this.otherData4 = null;
    this.otherData5 = null;
    this.otherData6 = null;
    this.otherData7 = null;
    this.otherData8 = null;
    this.otherData9 = null;
    this.otherData10 = null;
    this.otherData11 = null;
    this.otherData12 = null;
    this.otherData13 = null;
    this.otherData14 = null;
    this.published = null;
    this.afterDueDate = null;
    this.billDate = null;
    this.currentCharge = null;
    this.percentageBalance = null;
    this.utilityId = null;
    this.utilityName = null;
    this.merchantId = null;
    this.merchantLogo = null;
    this.consumerId = null;
    // this.templateId = null;
    this.graphList = null;
  }
}
