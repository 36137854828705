import { ChartData } from "./../../_shared/model/chart-data.model";
import { LatestBill } from "./../_shared/model/latest-bill";
import { Component, OnInit, Input, AfterViewInit, OnChanges, SimpleChanges, SimpleChange, EventEmitter, Output } from "@angular/core";
import { LineChart } from "../_shared/line-chart";
import * as c3 from "c3";
import * as d3 from "d3";
import { BillViewModel } from "../_shared/model/bill-view-model";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
@Component({
  selector: "app-bill-chart",
  templateUrl: "./bill-chart.component.html",
  styleUrls: ["./bill-chart.component.css"]
})
export class BillChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input("chartId") chartId: string;
  @Input("chartData") chartData: LatestBill;
  @Input("type") type: string;
  @Input("height") height: string;
  @Input("width") width: string;
  @Input("chartValue") chartValue: string;
  // @Output() public myOutput = new EventEmitter();
  canvas: any;
  context: any;
  data: number[];
  labels: string[];
  chartBill: LatestBill;
  bill: BillViewModel;
  amountChartData: any[];
  image: SafeUrl;
  isChartPresent: boolean;
  // private sanitizer: DomSanitizer
  constructor() {
    this.isChartPresent = true;
  }

  ngOnInit() {}
/* getting bills for create graph */
  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.chartData;
    if (this.type === "line") {
      this.chartBill = data.currentValue;
      if (this.chartBill.payments !== null) {
        this.amountChartData = this.generateChartData(this.chartBill.payments, "Amount");
        setTimeout(() => {
          this.createChart(this.amountChartData, Object(this.chartId), "Amount", "#fa9600");
        }, 0);
      }
    } else {
      this.bill = data.currentValue;
      if (this.bill.graphList !== null) {
        this.amountChartData = this.generateChartData(this.bill.graphList, "Gallons");
        setTimeout(() => {
          this.createChart(this.amountChartData, Object(this.chartId), "Gallons", "#46C2E2");
        }, 0);
      }
    }
  }

  ngAfterViewInit() {}

/* generate chart */
  createChart(chartData, id, chartName, color) {
    var chart = c3.generate({
      bindto: "#" + id,
      size: {
        height: this.height,
        width: this.width
      },
      data: {
        x: "x",
        columns: chartData,
        type: this.type
        // labels: true
      },
      point: {
        r: 6 // default is 2.5
      },
      color: {
        pattern: [color]
      },
      axis: {
        x: {
          type: "category",
          label: {
            // text: 'Month',
            position: "outer-middle"
          }
        },
        y: {
          // lines: [{value: 0}],
          label: {
            text: chartName,
            position: "outer-left"
          },
          padding: {
            top: 40
          }
        }
      }
    });
  }

/* calculation chart data */
  generateChartData(chartData, chartValue) {
    let amounts; // Labels to show on x-axis
    let months;
    if (chartData.length !== 0 && chartData !== null) {
      const chartDataChunks = [];
      initChartData(chartValue);
      let amount = Object.values(chartData).map((value: number) => value);
      let month = Object.keys(chartData).map((key: string) => key.toUpperCase());
      amounts = amounts.concat(amount);
      months = months.concat(month);
      if (months !== null && months.length !== 0) {
        chartDataChunks.push(months);
      }
      if (amounts !== null && amounts.length !== 0) {
        chartDataChunks.push(amounts);
      }
      return chartDataChunks;
    }

    function initChartData(value) {
      months = ["x"];
      amounts = [value];
    }
  }
}
