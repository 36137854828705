import { AdminLayoutComponent } from './admin-layout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { SharedModule } from 'app/_shared/shared.module';
import { AccountLayoutComponent } from './account.layout.component';
import { EmptyLayoutComponent } from './empty-layout.component';
import { TermsAndConditionsComponent } from 'app/_shared/terms-and-conditions/terms-and-conditions.component';
import { ModalModule } from 'ngx-bootstrap';
import { PrivacyPalicyComponent } from 'app/_shared/privacy-palicy/privacy-palicy.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaymentLayoutComponent } from './payment-layout.component';


@NgModule({
  declarations: [AccountLayoutComponent,AdminLayoutComponent, EmptyLayoutComponent, PaymentLayoutComponent],
  imports: [CommonModule, LayoutsRoutingModule, FormsModule, SharedModule,NgxSpinnerModule,
    ModalModule.forRoot()],
  exports: [AccountLayoutComponent],
  entryComponents: [TermsAndConditionsComponent,PrivacyPalicyComponent],
})
export class LayoutsModule {}
