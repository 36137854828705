export class Bill {
  billId: string;
  merchantId: string;
  utilityId: string;
  constructor() {
    this.billId = null;
    this.merchantId = null;
    this.utilityId = null;
  }
}
