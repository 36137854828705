export class SubsribedUtilities {
    public consumerName: string;
    public email: string;
    public subscribedConsumerName: string
    public merchantName: string;
    public utilityName: string;
    public accountNumber: string;
    constructor(){
        this.consumerName = null;
        this.email = null;
        this.subscribedConsumerName = null;
        this.merchantName = null;
        this.utilityName = null;
        this.accountNumber = null;
    }
}
