import { Component, OnInit } from "@angular/core";
import { PaymentsService } from "../payments.service";
import { CommonService } from "app/_core/common.service";
import { Router } from "@angular/router";
import { Consumer } from "app/account/_shared/model/consumer";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { PaymentSearchResult } from "../_shared/model/payment-search-result";
import { PaymentFilter } from "../_shared/model/payment-filter";
import { Utility } from "app/_shared/model/utility";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
@Component({
  selector: "app-payment-history",
  templateUrl: "./payment-history.component.html"
})
export class PaymentHistoryComponent implements OnInit {
  urls: string;
  consumer: Consumer;
  hideSearchBox = false;
  hidepaymenthistoryname = true;
  hidebordertitalblue = true;
  userPaymentHistoryList: PaymentSearchResult[];
  paymentFilter: PaymentFilter;
  paginationVM = new PaginationVM<PaymentSearchResult, PaymentFilter>();
  registeredUtilities: Utility[];
  pageNumber: number;
  maxDate: Date;
  minDate: Date;
  today = new Date();
  fToday = new Date();
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  bsConfig: Partial<BsDatepickerConfig>;
  lastSixMonth: Date;
  constructor(
    private paymentService: PaymentsService,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.paymentFilter = new PaymentFilter();
    this.userPaymentHistoryList = [];
    this.pageNumber = 1;
    this.paginationVM = <PaginationVM<PaymentSearchResult, PaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.userPaymentHistoryList,
      filters: this.paymentFilter
    };
    this.registeredUtilities = new Array<Utility>();
    this.consumer = this.commonService.getConsumerData();
    this.getRegisteredUtilities(this.consumer.id);
    this.hideSearchBox = false;
    var date = new Date(this.today.getTime() - 180 * 24 * 60 * 60 * 1000);
    this.lastSixMonth = new Date(date.getFullYear(), this.today.getMonth() - 6 + 1, 1, 0, 0, 0, 0);
  }

  ngOnInit() {
    this.getPayment();
    if (this.router.url === "/dashboard") {
      this.hideSearchBox = true;
    }
  }

  /* get payment list of specific consumer */
  getPayment() {
    this.spinner.show();
    this.paymentService.getPaymentHistoryByUserId(this.paginationVM, this.consumer.id).subscribe(
      res => {
        this.userPaymentHistoryList = [];
        if (res) {
          this.paginationVM = res;
          this.userPaymentHistoryList = new Array<PaymentSearchResult>();
          this.userPaymentHistoryList = this.paginationVM.records;
          // this.userPaymentHistoryList.forEach(element => {
          //   element.dueAmount = element.dueAmount.toString().includes(".") === false ? element.dueAmount + ".00" : element.dueAmount;
          // });
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* get registered utilities of consumer */
  getRegisteredUtilities(consumerId: string) {
    // this.spinner.show();
    this.paymentService.getUtilitiesFromConsumer(consumerId).subscribe(res => {
      if (res) {
        this.registeredUtilities = res;
        // this.spinner.hide();
      }
    });
    this.spinner.hide();
  }

  /* search in payment list */
  searchBillHistory() {
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<PaymentSearchResult, PaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.userPaymentHistoryList,
      filters: this.paymentFilter
    };
    this.paginationVM.filters = this.paymentFilter;
    this.paginationVM.filters.utilityId = this.paginationVM.filters.utilityId === "null" ? null : this.paginationVM.filters.utilityId;
    if(this.paymentFilter.from != null) {
      // tslint:disable-next-line: max-line-length
      this.paymentFilter.from = this.commonService.currentDateFormat(this.paymentFilter.from.getFullYear(),this.paymentFilter.from.getMonth(),this.paymentFilter.from.getDate(),0,0,0,0);
  }
    if(this.paymentFilter.to != null) {
      // tslint:disable-next-line: max-line-length
      this.paymentFilter.to = this.commonService.currentDateFormat(this.paymentFilter.to.getFullYear(),this.paymentFilter.to.getMonth(),this.paymentFilter.to.getDate(),23,59,0,0);
      }
    this.getPayment();
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getPayment();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getPayment();
    }
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  /* reset payment search form */
  reset(form: NgForm) {
    this.showingPage = 0;
    form.resetForm();
    this.paginationVM = <PaginationVM<PaymentSearchResult, PaymentFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.userPaymentHistoryList,
      filters: this.paymentFilter
    };

    this.paginationVM.filters = this.paymentFilter;
    this.getPayment();
  }

  /* get and view payment receipt */
  viewPaymentReceipt(paymentId: string, merchantId: string, utilityId: string) {
    const receipt = "/payment/payment-receipt?id=" + paymentId + "&merchantId=" + merchantId + "&utilityId=" + utilityId;
    this.urls = receipt;
  }

  /* export and download pdf */
  exportToPDF(id: string, merchantId: string, utilityId: string, accountNumber: string) {
    this.spinner.show();
    this.paymentService.ExportToPDF(id, merchantId, utilityId).subscribe(
      res => {
        if (res) {
          this.downloadFile(res.result, accountNumber, "application/pdf", "download");
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* download pdf file */
  downloadFile(data: string, accountNumber: string, fileType: any, type: string) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const downloadPDF = document.createElement("a");
    downloadPDF.href = url;
    const today = new Date();
    const date = this.commonService.formatDate(today);
    downloadPDF.download = accountNumber + "_" + date + ".pdf";
    downloadPDF.click();
    this.spinner.hide();
  }
}
