import { Receipt } from './../_shared/model/receipt-model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from 'app/_core/data.service';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { Utility } from 'app/_shared/model/utility';
import { PaymentSearchResult } from './_shared/model/payment-search-result';
import { PaymentFilter } from './_shared/model/payment-filter';
import { Proxynization } from 'app/_shared/model/proxynization-model';
import { ScheduledPayments } from './_shared/model/scheduled-payments';
import { Schedules } from './_shared/model/schedules';
import { RegisterUtility } from 'app/_shared/model/register-utility';
import { CardExpired } from './_shared/model/card-expired';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  constructor(private dataService: DataService) {}

  getPaymentHistoryByUserId(
    paymentSearchResult: PaginationVM<PaymentSearchResult, PaymentFilter>,
    consumerId: string
  ): Observable<PaginationVM<PaymentSearchResult, PaymentFilter>> {
    return this.dataService.post<PaginationVM<PaymentSearchResult, PaymentFilter>, PaginationVM<PaymentSearchResult, PaymentFilter>>(
      `payments/${consumerId}`,
      paymentSearchResult
    );
  }

  PayUsingACH(proxynization: Proxynization): Observable<Receipt> {
		return this.dataService.post<Receipt, Proxynization>('payments/ChargeTheCard', proxynization);
	}

  getUtilitiesFromConsumer(consumerId: string): Observable<Utility[]> {
    return this.dataService.get<Utility[]>(`utilities/${consumerId}`);
  }

  GetPaymentReceipt(paymentId: string, merchantId: string, utilityId: string): Observable<Receipt> {
    return this.dataService.get<Receipt>(`payments/getPayment/${paymentId}/${merchantId}/ ${utilityId}`);
  }

  ExportToPDF(paymentId: string, merchantId: string, utilityId: string): Observable<any> {
    const type = null;
    return this.dataService.get(`payments/pdf/${paymentId}/${merchantId}/${utilityId}/${type}`);
  }

  // getScheduledPayments(consumerId: string): Observable<Schedules[]> {
  //   return this.dataService.get<Schedules[]>(`scheduledpayments/${consumerId}`);
  // }

  getConsumersRegisteredUtilities(consumerId: string): Observable<Schedules[]> {
    return this.dataService.get<Schedules[]>(`scheduledpayments/${consumerId}/utilities`);
  }

  StoreScheduledPayment(payment: ScheduledPayments): Observable<string> {
    return this.dataService.post<string, ScheduledPayments>('scheduledpayments', payment);
  }

  storeMultipleSchedule(payment: ScheduledPayments): Observable<any> {
    return this.dataService.post('btxbiller/schduleauto', payment);
  }

  DesctivateScheduledPayment(scheduleId: string): Observable<boolean> {
    return this.dataService.put(`scheduledpayments/deactivate/${scheduleId}`);
  }

  // GetExpiredCardDetails(consumerId: string): Observable<CardExpired[]> {
  //   return this.dataService.get<CardExpired[]>(`scheduledpayments/${consumerId}/expiredcards`);
  // }
}
