export class OptInEBills {
  public id: string;
  public consumerId: string;
  public utilityId: string;
  public accountNumber: string;
  public isOptInEmail: boolean;
  public consumerEmail: string;
  public signatureData: string;
  public deviceInfo: DeviceInfo;
  public utilityConsent: string;
  constructor() {
    this.id = null;
    this.consumerId = null;
    this.utilityId = null;
    this.accountNumber = null;
    this.isOptInEmail = false;
    this.consumerEmail = null;
    this.signatureData = null;
    this.deviceInfo = new DeviceInfo();
    this.utilityConsent = null;
  }
}


export class DeviceInfo {
  operatingSystem: string;
  browserName: string;
  device: string;
  oSVersion: string;
  browserVersion: string;
  latitude: string;
  longitude: string;
  iPaddress: string;

  constructor() {
    this.operatingSystem = null;
    this.browserName = null;
    this.device = null;
    this.oSVersion = null;
    this.browserVersion = null;
    this.latitude = null;
    this.longitude = null;
    this.iPaddress = null;

  }
}
