import { ConsumerBill } from "./../bills/_shared/model/consumer-bill";
import { LoginVM } from "./../_shared/model/login-vm";
import { RemoteValidation } from "app/_shared/model/remote-validation.model";
import { ConsumerDetails } from "./../_shared/model/consumer-details";
import { Injectable } from "@angular/core";
import { User } from "app/_shared/model/user.model";
import { DataService } from "app/_core/data.service";
import { Observable, BehaviorSubject } from "rxjs";
import { Login } from "app/_shared/model/login.model";
import { ChangePassword } from "app/_shared/model/change-password";
import { UtilityMaster } from "app/_shared/model/utility-master";
import { RegisterUtility } from "app/_shared/model/register-utility";
import { Merchant } from "app/administration/_shared/model/merchant";
import { Utility } from "app/_shared/model/utility";
import { ConsumerUtility } from "app/_shared/model/consumer-utility";
import { UtilityVM } from "app/_shared/model/utility-vm";
import { NewUser } from "app/_shared/model/newUser.model";
import { ForgotPasswordViewModel } from "./_shared/model/forgot-password-view-model";
import { Consumer } from "./_shared/model/consumer";
import { Statistics } from "app/_shared/model/statistics";
import { PaymentSuccess } from "app/_shared/model/payment-success";
import { NewUtility } from "./_shared/model/new-utility";
import { ContactUs } from "app/_shared/model/contact-us";
import { SiteVisit } from "app/_shared/model/site-visit";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { BillFilter } from "app/bills/_shared/model/bill-filter";
import { ConsumerNotifications } from "app/notification/_shared/model/consumer-notifications";
import { NotificationSetting } from 'app/setting/shared/model/notification-setting';

@Injectable({
  providedIn: "root"
})
export class AccountService {
  user: User;
  private updateUserOnDashboard = new BehaviorSubject(false);
  UserUpdate = this.updateUserOnDashboard.asObservable();

  private updateMessageOnDashboard = new BehaviorSubject(false);
  MessageUpdate = this.updateMessageOnDashboard.asObservable();

  private showMessage = new BehaviorSubject(false);
  show = this.showMessage.asObservable();

  private regSuccessMsg = new BehaviorSubject(false);
  successMsgRegistration = this.regSuccessMsg.asObservable();

  private checkCreditCardDetails = new BehaviorSubject(false);
  CheckCardExpiry = this.checkCreditCardDetails.asObservable();

  constructor(private dataService: DataService) {}

  login(user: LoginVM): Observable<any> {
    return this.dataService.post("account/token", user);
  }

  sendOtp(email: string): Observable<boolean> {
    return this.dataService.get<boolean>("account/password/" + email + "/token");
  }

  getStatistics(consumerId: string) {
    return this.dataService.get<Statistics>("consumerDashboard/" + consumerId + "/statistics");
  }

  CheckOTP(resetPassword: RemoteValidation): Observable<boolean> {
    return this.dataService.get<boolean>(`Consumer/${resetPassword.key}/${resetPassword.value}`);
  }

  resetPassword(model: ForgotPasswordViewModel): Observable<ForgotPasswordViewModel> {
    return this.dataService.post<ForgotPasswordViewModel, ForgotPasswordViewModel>("account/password/", model);
  }

  addUser(user: User): Observable<User> {
    return this.dataService.post<User, User>("Consumer/AddUser", user);
  }

  UpdateUser(consumer: Consumer): Observable<Consumer> {
    return this.dataService.post<Consumer, Consumer>("account/update", consumer);
  }

  GetProfile(email: string): Observable<Consumer> {
    return this.dataService.get<Consumer>("account/profile/" + email);
  }

  registerUser(newUser: NewUser): Observable<any> {
    return this.dataService.post("account", newUser);
  }

  sendRegistrationOtp(email: string): Observable<string> {
    return this.dataService.post<string, object>("account/" + email + "/token", {});
  }

  ChangePassword(changePassword: ChangePassword): Observable<any> {
    return this.dataService.put("account/password", changePassword);
  }

  getMasterUtilities(): Observable<UtilityMaster[]> {
    // delete after register utility
    return this.dataService.get("Utility/GetMasterUtilities");
  }

  getRegisteredUtilities(consumerId): Observable<RegisterUtility[]> {
    return this.dataService.get<RegisterUtility[]>(`consumers/${consumerId}/utilities`);
    // return this.dataService.get('Utility/getRegisteredUtilities');
  }

  getMerchants(): Observable<Merchant[]> {
    return this.dataService.get('merchants');
  }

  getMerchantsByHost(host: string): Observable<Merchant[]> {
    return this.dataService.get(`merchants/billers/${host}`);
  }

  getUtilitiesFromMerchant(merchantId: string): Observable<Utility[]> {
    // delete after register utility
    return this.dataService.get<Utility[]>(`merchants/${merchantId}/utilities`);
  }

  DeleteUtility(id: string, isActive: boolean): Observable<boolean> {
    return this.dataService.delete(`consumers/${id}`);
  }

  updateProfileOnNavbar(isReload: boolean) {
    this.updateUserOnDashboard.next(isReload);
  }

  updatePasswordMessage(isReload: boolean) {
    this.updateMessageOnDashboard.next(isReload);
  }

  showMessageOnLogin(isReload: boolean) {
    this.showMessage.next(isReload);
  }

  showRegMessageOnAddUtility(isReload: boolean) {
    this.regSuccessMsg.next(isReload);
  }

  showCardExpiry(isExpired: boolean) {
    this.checkCreditCardDetails.next(isExpired);
  }

  LogOut(sessionId: string): Observable<any> {
    return this.dataService.get<any>("account/logout/" + sessionId);
  }

  getBill(consumerBill: ConsumerBill): Observable<ConsumerBill> {
    return this.dataService.get<ConsumerBill>(
      `bills/quickbills/${consumerBill.utilityId}/${consumerBill.merchantId}/${consumerBill.accountNumber}`
    );
  }

  getUtilities(
    consumerId: string,
    billSearchResult: PaginationVM<RegisterUtility, BillFilter>
  ): Observable<PaginationVM<RegisterUtility, BillFilter>> {
    return this.dataService.post<PaginationVM<RegisterUtility, BillFilter>, PaginationVM<RegisterUtility, BillFilter>>(
      `consumers/${consumerId}/registeredUtilities`,
      billSearchResult
    );
  }

  registerUtility(consumerId: string, newUtility: NewUtility): Observable<any> {
    return this.dataService.post<any, {}>(`consumers/${consumerId}/utilities`, newUtility);
  }

  checkSubscribedUtility(consumerId: string, newUtility: NewUtility): Observable<any> {
    return this.dataService.post(`consumers/${consumerId}/checksubscribedutilities`, newUtility);
  }

  postContact(contactUs: ContactUs): Observable<ContactUs> {
    return this.dataService.post("account/contactUs", contactUs);
  }

  addSiteVisit(site: SiteVisit): Observable<any> {
    return this.dataService.post("account/sitevisit", site);
  }

  notificationSetting(notification: NotificationSetting): Observable<any> {
    return this.dataService.post("notifications/notificationsetting", notification);
  }

  getConsumerNotificationSetting(email: string): Observable<any> {
    return this.dataService.get<any>("consumers/" + email);
  }

  getBillerContent(data: any): Observable<any> {
    return this.dataService.post('account/biller/content',data );
  }
}
