import { DialogData } from './../../account/add-utility/add-utility.component';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.css']
})
export class ModalContentComponent implements OnInit {
 
   ngOnInit() {
   }
  
}
