export class BillFilter {
  public utilityId: string;
  public from: Date;
  public to: Date;
  public status: string;
  public accountNumber: string;

  constructor() {
    this.utilityId = null;
    this.from = null;
    this.to = null;
    this.status = null;
    this.accountNumber = null;
  }
}
