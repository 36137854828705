import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BillsService } from 'app/bills/bills.service';
import { CommonService } from 'app/_core/common.service';
import { Consumer } from 'app/account/_shared/model/consumer';
import { PaymentsService } from '../payments.service';
import {
  ScheduledPayments,
  PaymentSchedule,
} from '../_shared/model/scheduled-payments';
import { NgxSpinnerService } from 'ngx-spinner';
import { Schedules } from '../_shared/model/schedules';
import { RegisterUtility } from 'app/_shared/model/register-utility';
import { ToastrService } from 'ngx-toastr';
declare var intellipay: any;

@Component({
  selector: 'app-auto-or-recurring-payment',
  templateUrl: './auto-or-recurring-payment.component.html',
  styleUrls: ['./auto-or-recurring-payment.component.css'],
})
export class AutoOrRecurringPaymentComponent implements OnInit {
  @ViewChild('payments1', { static: false }) payments1: ElementRef;
  @ViewChild('payments2', { static: false }) payments2: ElementRef;
  @ViewChild('payments3', { static: false }) payments3: ElementRef;
  @ViewChild('payments4', { static: false }) payments4: ElementRef;
  @ViewChild('payments5', { static: false }) payments5: ElementRef;
  @ViewChild('payNowDashboard', { static: false }) payNowDashboard: ElementRef;

  isSetupView: boolean;
  showOneToThirtyDay: boolean;
  isChangedPayment: boolean;
  paymetType: string;
  showAmountField: boolean;
  schedules: Schedules[];
  schedulePay: ScheduledPayments;
  // payDate: string;
  payDay: string;
  consumer: Consumer;
  scheduleRecord: ScheduledPayments;
  selectUtility: ScheduledPayments;
  isSetupBtnDisabled : boolean;
  selectedRow: string;
  desc: string;
  // utilities: Schedules[];
  disableList: boolean;

  constructor(
    private billService: BillsService,
    private commonService: CommonService,
    private paymentsService: PaymentsService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService
  ) {
    this.isSetupBtnDisabled = false;
    this.isSetupView = false;
    this.showOneToThirtyDay = false;
    this.isChangedPayment = false;
    this.showAmountField = false;
    this.consumer = this.commonService.getConsumerData();
    this.getSubscribedEbills(this.consumer.id);
    this.schedules = new Array<Schedules>();
    this.schedulePay = new ScheduledPayments();
    // this.payDate = null;
    this.payDay = null;
    this.selectUtility = new ScheduledPayments();
    this.selectedRow = null;
    this.disableList = true;
  }

  ngOnInit() {
   // this.callIntellipay();
   this.schedulePay.paymentType = 'Auto Payment';
  }

  getSubscribedEbills(consumerId) {
    this.spinner.show();
    this.paymentsService
      .getConsumersRegisteredUtilities(consumerId)
      .subscribe(
        (res) => {
          if (res) {
            this.schedules = res;
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  updateCardDetails(data : ScheduledPayments){
    this.spinner.show();
   // this.disableList = false;
    this.schedulePay = new ScheduledPayments();
   // this.selectedRow = data.accountNumber;
    this.schedulePay.id = data.id;
    // this.schedulePay.merchant = data.merchant;
    // this.schedulePay.utility = data.utility;
     this.schedulePay.accountNumber = data.accountNumber;
    // this.schedulePay.paymentSchedule = data.paymentSchedule;
    // this.schedulePay.paymentType = data.paymentType;
    // this.schedulePay.scheduleType = data.scheduleType;
    // this.schedulePay.transaction = data.transaction;
    // this.schedulePay.recurringAmount = data.recurringAmount;
    // this.schedulePay.consumer = {
    //   id: this.consumer.id,
    //   name: this.consumer.firstName + ' ' + this.consumer.lastName,
    // };
    const _this = this;
    this.callIntellipay(data.merchant.id, data.utility.id);
    setTimeout(function () {
      _this.pay();
      _this.spinner.hide();
    }, 4000);
  }

  callIntellipay(merchantId: string, utilityId: string) {
    this.billService.intelliPayIn(merchantId, utilityId).subscribe((res) => {
      if (res) {
        const rg = document.createRange();
        document.documentElement.appendChild(
          rg.createContextualFragment(res.result)
        );
        intellipay.setItemLabel('account', 'Account Number');
        intellipay.disable('email');
        intellipay.initialize();
        const _this = this;
        intellipay.runOnApproval(function (response) {
          _this.storeScheduledPayments(JSON.stringify(response));
        });
      }
    });
  }

  storeScheduledPayments(response) {
    const paymentDeyails = JSON.parse(response);
    this.schedulePay.transaction = {
      customerId: paymentDeyails.custid,
      status: paymentDeyails.status,
      paymentType: paymentDeyails.paymenttype
    };
    this.paymentsService.StoreScheduledPayment(this.schedulePay).subscribe((res) => {
        if (res) {
          this.isSetupView = false;
          this.selectedRow = null;
          this.disableList = true;
          this.getSubscribedEbills(this.consumer.id);
          this.toaster.success('Setup activated successfully');
          this.schedulePay = new ScheduledPayments();
        }
      });
  }

  pay() {
    this.payments1.nativeElement.attributes[1].nodeValue = 'selectpay';
    this.payments2.nativeElement.attributes[2].nodeValue = intellipay.setStoreOnly(true);
    this.payments3.nativeElement.attributes[3].nodeValue = 'radio';
    this.payments4.nativeElement.attributes[4].nodeValue = 'StoreOnly';
    this.payments5.nativeElement.attributes[6].nodeValue = this.schedulePay.accountNumber;
    this.payNowDashboard.nativeElement.click();
  }

  selectPayment() {
    this.isSetupBtnDisabled = false;
    if (this.schedulePay.paymentType === 'Auto Payment') {
      this.showAmountField = false;
      this.isChangedPayment = true;
    } else {
      this.isSetupBtnDisabled = false;
      this.showAmountField = true;
      this.isChangedPayment = true;
    }
  }

  // onChangeAmount(){
  //   this.schedulePay.recurringAmount = amt;
  // }


  radioValue(description: string,scheduleType: string) {
    // this.payDate = null;
    this.payDay = null;
    this.schedulePay.scheduleType = scheduleType;
    this.schedulePay.paymentSchedule.day = scheduleType === 'beforeduedate' ? '1' : null;
    this.schedulePay.paymentSchedule.description = description;
    if(this.schedulePay.paymentType === 'Recurring Payment' ){
      if((this.schedulePay.scheduleType === 'billgenerate') && (this.schedulePay.recurringAmount)){
        this.isSetupBtnDisabled = true;
      }
      else if((this.schedulePay.scheduleType === 'beforeduedate' || this.schedulePay.scheduleType === 'dayofmonth') && (this.schedulePay.recurringAmount)) {
        this.isSetupBtnDisabled = false;
      }
    }else {
      if(this.schedulePay.scheduleType === 'billgenerate' || this.schedulePay.scheduleType === 'beforeduedate'){
        this.isSetupBtnDisabled = true;
      }
     else if( this.schedulePay.scheduleType === 'dayofmonth'){
        this.isSetupBtnDisabled = false;
      }
    }
  }

  payDateSelected(selectedRadio: string,value: string) {
    if (value != null) {
      this.schedulePay.paymentSchedule.day = value;
      this.schedulePay.paymentSchedule.description = '';
      this.schedulePay.paymentSchedule.description = this.ordinal_suffix_of(value) + ' ' + selectedRadio;
    }
    if (this.schedulePay.paymentType === 'Recurring Payment' ) {
      if(this.schedulePay.paymentSchedule.description && this.schedulePay.paymentSchedule.day && this.schedulePay.recurringAmount){
        this.isSetupBtnDisabled = true;
      }
    } else {
      if(this.schedulePay.paymentSchedule.description && this.schedulePay.paymentSchedule.day){
        this.isSetupBtnDisabled = true;
      }
    }

  }

  ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
}

  openSetupDiv(value: boolean, data: ScheduledPayments) {
    this.disableList = false;
    this.schedulePay = new ScheduledPayments();
    this.selectedRow = data.accountNumber;
    this.isSetupView = value;
    this.schedulePay.merchant = data.merchant;
    this.schedulePay.utility = data.utility;
    this.schedulePay.accountNumber = data.accountNumber;
    this.schedulePay.consumer = {
      id: this.consumer.id,
      name: this.consumer.firstName + ' ' + this.consumer.lastName,
    };
    this.callIntellipay(data.merchant.id, data.utility.id);
  }

  onSelect(schedule: ScheduledPayments, modal) {
    modal.show();
    this.selectedRow = schedule.accountNumber;
    this.selectUtility = schedule;
  }

  deActivateSetup(schedule,modal){
    this.paymentsService.DesctivateScheduledPayment(schedule.id).subscribe((res) => {
        if (res) {
          modal.hide();
          this.getSubscribedEbills(this.consumer.id);
          this.toaster.success('Setup de-activated successfully');
        }
      });
  }

  /* close e-bill moadl */
  closeModal(modal) {
    modal.hide();
    this.selectedRow = null;
    this.getSubscribedEbills(this.consumer.id);
  }

  closeSetup(value: boolean){
    this.disableList = true;
    this.schedulePay = new ScheduledPayments();
    this.selectedRow = null;
    this.isSetupView = value;
    this.getSubscribedEbills(this.consumer.id);
  }
}
