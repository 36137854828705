import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Router } from '@angular/router';
import { AccountService } from 'app/account/account.service';

@Component({
  selector: 'app-update-card-details',
  templateUrl: './update-card-details.component.html',
  styleUrls: ['./update-card-details.component.css']
})
export class UpdateCardDetailsComponent implements OnInit {
  accountNumber: string;
  cardExpiry: string;
  constructor(public bsModalRef: BsModalRef, private router: Router, private accountService: AccountService) {
   }

  ngOnInit() {
    console.log(this.accountNumber);
  }

  redirect(modal) {
    modal.hide();
    this.router.navigate(['/payments/auto-recurring-payment']);
  }

}
