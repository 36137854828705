import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './layouts/main.component';
import { StaticComponent } from './layouts/static.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutsModule } from './layouts/layouts.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ModalContentComponent } from './_shared/modal-content/modal-content.component';
import { Ng2DeviceDetectorModule } from 'ng2-device-detector';
import { JsonpModule } from '@angular/http';
import { TextMaskModule } from 'angular2-text-mask';
import { HttpIterceptorService } from './_core/http-iterceptor.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { UpdateCardDetailsComponent } from './payments/update-card-details/update-card-details.component';

@NgModule({
	declarations: [ AppComponent, StaticComponent, MainComponent ],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		NgxSpinnerModule,
		BrowserAnimationsModule,
    LayoutsModule,
    Ng2DeviceDetectorModule.forRoot(),
    JsonpModule,
    TextMaskModule,
    PopoverModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      countDuplicates: false,
      progressBar: true
    }) // ToastrModule added
  ],
  providers:[{ provide: HTTP_INTERCEPTORS, useClass: HttpIterceptorService, multi: true }],
  bootstrap: [ AppComponent ],
  entryComponents: [ModalContentComponent, UpdateCardDetailsComponent]
})
export class AppModule {}
