export class User {
  id: string;
  customerId: string;
  userName: string;
  password: string;
  confirmPassword: string;
  name: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  role: string;
  isActive: boolean;
  isLocked: boolean;
  createdBy: string;
  createdOn: string;
  updatedOn: string;
  updatedBy: string;
  companyName: string;
  otp: string;
  firstName: string;
  lastName: string;
  User() {
    this.id = null;
    this.customerId = null;
    this.name = null;
    this.email = null;
    this.userName = null;
    this.confirmPassword = null;
    this.password = null;
    this.phone = null;
    this.address = null;
    this.city = null;
    this.state = null;
    this.zipCode = null;
    this.role = null;
    this.createdOn = null;
    this.updatedOn = null;
    this.createdBy = null;
    this.updatedBy = null;
    this.isActive = false;
    this.isLocked = false;
    this.companyName = null;
    this.otp = null;
    this.firstName = null;
    this.lastName = null;

  }
}
