export class Pagination {
  pageNumber: number;
  totalPage: number;
  pageSize: number;
  totalCount: number;
  sortBy: string;
  sortType: string;
  constructor() {
    this.pageNumber = 0;
    this.pageSize = 5;
    this.totalCount = 0;
    this.sortBy = 'Id';
    this.sortType = 'desc';
  }

}
export class PaginationVM<T, U> extends Pagination {
  public records: T[];
  public filters: U;
}
