import { NgxSpinnerService } from "ngx-spinner";
import { PaymentSuccess } from "app/_shared/model/payment-success";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { Bill } from "./../_shared/model/bill";
import { Component, OnInit, Self, ElementRef, ViewChild } from "@angular/core";
import { BillsService } from "../bills.service";
import { Consumer } from "app/account/_shared/model/consumer";
import { CommonService } from "app/_core/common.service";
import { LatestBill } from "../_shared/model/latest-bill";
import { BsModalRef } from "ngx-bootstrap";
import { OptInEBills, DeviceInfo } from "../_shared/model/opt-in-e-bills";
import { IntelliPayResponseVM } from "app/payments/_shared/model/intelli-pay-response-vm";
declare var intellipay: any;
declare var jQuery: any;
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { Ng2DeviceService } from "ng2-device-detector";
import { Jsonp } from "@angular/http";
import { float } from "html2canvas/dist/types/css/property-descriptors/float";
declare var dataJson: any;
declare var $: any;
declare var SignaturePad: any;

@Component({
  selector: "app-bill-details",
  templateUrl: "./bill-details.component.html",
})
export class BillDetailsComponent implements OnInit {
  @ViewChild("payNowDashboard", { static: false }) payNowDashboard: ElementRef;
  @ViewChild("payNowDashboard1", { static: false })
  payNowDashboard1: ElementRef;
  @ViewChild("payNowDashboard2", { static: false })
  payNowDashboard2: ElementRef;

  signaturePad: any;
  canvas: HTMLElement;
  bill: Bill;
  urls: string;
  consumerId: string;
  latestBills: LatestBill[];
  banners: LatestBill[];
  paymentSuccess: PaymentSuccess;
  utilityId: string;
  merchantId: string;
  billId: string;
  accountNumber: string;
  billData: LatestBill;
  today: Date;
  bsModalRef: BsModalRef;
  consumer: Consumer;
  optInBill: LatestBill;
  optInForEbill: OptInEBills;
  lightbox: string;
  intellipayVM: IntelliPayResponseVM;
  showMessage: boolean;
  deviceInfo: any;
  deviceData: DeviceInfo;
  IsValidEditable: any;
  constructor(
    private billService: BillsService,
    private router: Router,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private toaster: ToastrService,
    private deviceService: Ng2DeviceService,
    private _jsonp: Jsonp
  ) {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
    this.bill = new Bill();
    this.consumerId = this.commonService.getConsumerUserId();
    this.consumer = this.commonService.getConsumerData();
    this.latestBills = new Array<LatestBill>();
    this.billData = new LatestBill();
    this.optInBill = new LatestBill();
    this.optInForEbill = new OptInEBills();
    this.intellipayVM = new IntelliPayResponseVM();
    this.showMessage = false;
    this.deviceData = new DeviceInfo();
  }

  ngOnInit() {
    this.getDetails();
    // this.callIntellipay();
    this.billService.billsReload.subscribe((load) => {
      if (load) {
        this.getDetails();
        this.billService.updateBillDetailsList(false);
      }
    });

    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.canvas = document.getElementById("signature-pad");
    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: "rgb(255, 255, 255)",
    });
    this.GetDeviceDetails();
  }

  clearSignature() {
    this.signaturePad.clear();
    this.showMessage = false;
  }

  // ngAfterViewInit(){
  //   this.latestBills.forEach(item => {
  //     if (item.billType === "intellipay") {
  //       this.callIntellipay(item.merchant.id);
  //     }
  //   });
  // }

  /* view pdf terms of service */
  downloadPdfVersion(path: string) {
    const url = path;
    window.open(url, "Download");
  }

  /* view template wise bills */
  viewBillAnotherTab(
    billId,
    merchantId,
    utilityId,
    accountNumber,
    merchantName,
    utilityName,
    tempVersion
  ) {
    this.urls = this.commonService.viewBillAnotherTab(
      billId,
      merchantId,
      utilityId,
      accountNumber,
      merchantName,
      utilityName,
      tempVersion
    );
  }

  /* passing values to component*/
  redirect(billId, utilityId, merchantId, accountNumber) {
    this.router.navigate(["/bills/utility-bill-details"], {
      queryParams: {
        billId: billId,
        merchantId: merchantId,
        utilityId: utilityId,
        accountNumber: accountNumber,
      },
    });
  }

  /* getting bills*/
  getDetails() {
    this.spinner.show();
    this.billService.getBillDetails(this.consumerId).subscribe((res) => {
      if (res) {
        this.latestBills = res;
        // this.latestBills.forEach(part => {
        // part.dateAfterDue = new Date(part.dueDate);
        // tslint:disable-next-line: max-line-length
        // part.totalAmount =  this.today <= part.dateAfterDue ? part.totalDueNow : parseFloat(part.totalDueNow) > parseFloat(part.afterDueDate) ? part.totalDueNow : part.afterDueDate;
        // part.previousBalance = part.previousBalance;
        // part.prevBalCutOffDate = new Date(part.cutOffDate);
        // part.afterDueDateAmt = parseFloat(part.afterDueDate);
        // if (part.billType === "intellipay") {
        //   this.callIntellipay(part.merchant.id);
        // }
        // });
        this.spinner.hide();
      }
    });
  }

  /* check active bill */
  isActive(billId: string) {
    return billId === this.latestBills[0].billId;
  }

  /* pass value to component through query parameter*/
  viewPaymentPopUp(item: LatestBill) {
    this.router.navigate(["/payment-wizard"], {
      queryParams: {
        merchantId: item.merchant.id,
        utilityId: item.utility.id,
        accountNumber: item.accountNumber,
      },
    });
  }

  /* open subscribed pop up*/
  openSubscribedModal(item: LatestBill, modal) {
    modal.show();
    this.showMessage = false;
    this.signaturePad.clear();
    this.optInBill = item;
  }

  /* close modal and load bills*/
  closeOptIn(modal) {
    modal.hide();
    this.signaturePad.clear();
    this.showMessage = false;
    this.getDetails();
  }

  /* subscribe and unsubscribe opt in bills*/

  optInEbill(
    modal,
    accountNumber,
    utilityId,
    merchantId,
    consumerEmail,
    isOptIn,
    conscent
  ) {
    if (!this.signaturePad.isEmpty()) {
      this.spinner.show();
      this.optInForEbill = {
        id: null,
        accountNumber: accountNumber,
        consumerId: this.consumerId,
        utilityId: utilityId,
        isOptInEmail: isOptIn,
        consumerEmail: consumerEmail,
        signatureData: this.signaturePad.toDataURL("image/png"),
        deviceInfo: this.deviceData,
        utilityConsent: conscent,
      };
      this.billService
        .OptInForEbills(this.optInForEbill, merchantId)
        .subscribe((res) => {
          if (res) {
            modal.hide();
            this.getDetails();
            this.spinner.hide();
          }
          () => {
            this.spinner.hide();
          };
        });
    } else {
      this.showMessage = true;
    }
  }

  optInEbillUnsubscribe(
    modal,
    accountNumber,
    utilityId,
    merchantId,
    consumerEmail,
    isOptIn,
    conscent
  ) {
    this.spinner.show();
    this.optInForEbill = {
      id: null,
      accountNumber: accountNumber,
      consumerId: this.consumerId,
      utilityId: utilityId,
      isOptInEmail: isOptIn,
      consumerEmail: consumerEmail,
      signatureData: this.signaturePad.toDataURL("image/png"),
      deviceInfo: this.deviceData,
      utilityConsent: conscent,
    };
    this.billService
      .OptInForEbills(this.optInForEbill, merchantId)
      .subscribe((res) => {
        if (res) {
          modal.hide();
          this.getDetails();
          this.spinner.hide();
        }
        () => {
          this.spinner.hide();
        };
      });
  }

  /* call to azure blob container to load pdf*/
  viewPdfFromCloud(pdfName: string, containerName: string, item: LatestBill) {
    this.urls = this.commonService.viewPdfFromCloud(pdfName, containerName);
    this.siteVisit(item.merchant.id, item.utility.id, item.accountNumber);
  }

  callIntellipay() {
    // this.spinner.show();
    this.billService.intelliPay().subscribe((res) => {
      if (res) {
        const rg = document.createRange();
        document.documentElement.appendChild(
          rg.createContextualFragment(res.result)
        );
        intellipay.setItemLabel("account", "Account Number");
        intellipay.setItemLabel("button", "Pay Now"); //Set the pay button text
        intellipay.disable("email");
        intellipay.initialize();
        // this.spinner.hide();
        const self = this;
        intellipay.runOnApproval(function (response) {
          self.storeIntelliPayResponse(JSON.stringify(response));
        });
      }
    });
  }

  storeIntelliPayResponse(response) {
    this.intellipayVM.intelliPayResponse = response;
    this.billService
      .storeIntelliPayResponse(this.intellipayVM)
      .subscribe((res) => {
        if (res) {
          if (res.paymentStatus === "success") {
            this.getDetails();
            this.billService.reloadPaymentAndBillsDashboard(true);
          }
          if (res.paymentStatus === "failed") {
            this.toaster.error("Transaction failed");
          }
          this.siteVisit(
            this.intellipayVM.merchantId,
            this.intellipayVM.utilityId,
            this.intellipayVM.accountNumber
          );
        }
      });
  }

  pay(bill: LatestBill, isPreviousBalance) {
    this.intellipayVM = {
      consumerId: this.consumerId,
      billId: bill.billId,
      merchantId: bill.merchant.id,
      utilityId: bill.utility.id,
      accountNumber: bill.accountNumber,
      intelliPayResponse: null,
      email: this.consumer != null ? this.consumer.email : null,
      paymentChannel: "BillTrax",
      paidPreviousBalance: isPreviousBalance,
      paymentMode: "WEB",
    };
    if(bill.allowPartialPayment === 'yes'){
      if (bill.totalDueAmount > 0) {
        this.payNowDashboard1.nativeElement.attributes[5].nodeValue = isPreviousBalance ? bill.previousBalance : bill.totalDueAmount;
      } else {
        this.payNowDashboard1.nativeElement.attributes[5].nodeValue = -1.00;
      }
} else {
      if (bill.totalDueAmount > 0) {
        this.payNowDashboard1.nativeElement.attributes[5].nodeValue = isPreviousBalance ? bill.previousBalance : bill.totalDueAmount;
      } else {
        this.payNowDashboard1.nativeElement.attributes[5].nodeValue = -1.00;
      }
    }
    //   this.payNowDashboard1.nativeElement.attributes[5].nodeValue = -1.00
    // } else{
    //   this.payNowDashboard1.nativeElement.attributes[5].nodeValue =isPreviousBalance === true ? bill.previousBalance : bill.totalDueAmount;
    // }
    this.payNowDashboard2.nativeElement.attributes[5].nodeValue =
      bill.accountNumber;
    this.payNowDashboard.nativeElement.click();

    //   const navigationExtras: NavigationExtras = {
    //     queryParams: {
    //       consumerId: this.consumerId,
    //       billId: bill.billId,
    //       merchantId: bill.merchant.id,
    //       utilityId: bill.utility.id,
    //       accountNumber: bill.accountNumber,
    //       intelliPayResponse: null,
    //       email: this.consumer != null ? this.consumer.email : null,
    //       paymentChannel : 'BillTrax',
    //       paidPreviousBalance : isPreviousBalance,
    //       paymentMode: 'WEB',
    //       amount: isPreviousBalance === true ? bill.previousBalance : bill.totalDueAmount
    //     }
    // };
    //    this.router.navigate(['payments/payment-initialize'], navigationExtras);
  }

  GetDeviceDetails() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = position.coords;
        this.deviceData.latitude = location.latitude.toString();
        this.deviceData.longitude = location.longitude.toString();
      });
    }
    this._jsonp
      .get("//api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK")
      .subscribe((response) => {
        const data = response.json();
        this.deviceData.iPaddress = data.ip;
        this.deviceData.operatingSystem = data.os;
      });
    this.deviceData.oSVersion = this.deviceInfo.os_version;
    this.deviceData.browserVersion = this.deviceInfo.browser_version;
    this.deviceData.browserName = this.deviceInfo.browser;
    this.deviceData.device = this.deviceInfo.device;
  }

  siteVisit(merchantId, utilityId, accountNumber) {
    this.commonService
      .siteVisit(merchantId, utilityId, accountNumber)
      .subscribe(
        (res) => {
          if (res) {
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  callIntellipayNow(bill: LatestBill, isPrevious) {
    this.getConfiguration(bill.merchant.id);
    if (bill.totalDueAmount > 0) {
      this.spinner.show();
      this.billService.intelliPayIn(bill.merchant.id, bill.utility.id).subscribe((res) => {
        if (res) {
          const rg = document.createRange();
          document.documentElement.appendChild(
            rg.createContextualFragment(res.result)
          );
          intellipay.setItemLabel("account", "Account Number");
          intellipay.setItemLabel("button", "Pay Now"); //Set the pay button text
          if (this.IsValidEditable) {
            intellipay.enable('amount');
          }
          intellipay.disable("email");
          intellipay.initialize();
          // this.spinner.hide();
          const _this = this;
          intellipay.runOnApproval(function (response) {
            _this.storeIntelliPayResponse(JSON.stringify(response));
          });
          // if (intellipay) {
          //   this.pay(bill, isPrevious);
          //   this.spinner.hide();
          // }
          setTimeout(function () {
            _this.pay(bill, isPrevious);
            _this.spinner.hide();
          }, 3000);
        }
      });
    }

  }

    //7 aug 2023
    getConfiguration(merchantId) {
      this.spinner.show();
      this.billService.getConfiguration(merchantId).subscribe(
        (res) => {
          if (res) {
            this.IsValidEditable = res.IsValidEditable;
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
        }
      );
    }

}
