export class SiteVisit {
  id: string;
  consumerId: string;
  consumerEmail: string;
  utilityId: string;
  accountNumber: string;
  merchantId: string;
	operatingSystem: string;
	browserName: string;
	device: string;
	osVersion: string;
	browserVersion: string;
	latitude: string;
	longitude: string;
	ipAddress: string;
  visitTime: Date;
  sessionId: string;
	constructor() {
    this.id = null;
    this.consumerId = null;
    this.utilityId = null;
    this.accountNumber = null;
    this.merchantId = null;
    this.accountNumber = null;
		this.operatingSystem = null;
		this.browserName = null;
		this.device = null;
		this.osVersion = null;
		this.browserVersion = null;
		this.latitude = null;
		this.longitude = null;
		this.ipAddress = null;
    this.visitTime = null;
    this.sessionId = null;
	}
}
