export class RegisterUtility {
  id: string;
  name: string;
  accountNumber: string;
  utility: string;
  utilityId: string;
  address: string;
  logo: string;
  isActive: boolean;
  merchantId: string;
  isOptInEmail: boolean;
  accountName: string;
  accountEmail: string;
  utilityConsent: string;

  constructor() {
    this.id = null;
    this.name = null;
    this.accountNumber = null;
    this.utility = null;
    this.utilityId = null;
    this.address = null;
    this.logo = null;
    this.isActive = null;
    this.merchantId = null;
    this.isOptInEmail = false;
    this.accountName = null;
    this.accountEmail = null;
    this.utilityConsent = null;
  }
}
