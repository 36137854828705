export class ChangePassword {
	oldPassword: string;
	password: string;
	confirmPassword: string;
	constructor() {
		this.oldPassword = null;
		this.password = null;
		this.confirmPassword = null;
	}
}
