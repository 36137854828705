import { ItemId } from "./item-id";
import { BroadcastMessages } from "./broadcast-messages";

export class LatestBill {
  public billId: string;
  public logo: string;
  public merchant: ItemId;
  public utility: ItemId;
  public consumer: ItemId;
  public meterNumber: string;
  public contactNumber: string;
  public previousBalance: number;
  public billDate: Date;
  public dueDate: Date;
  // public lastPayment: number;
  public accountNumber: string;
  public afterDueDate: string;
  public dueAmount: number;
  public payments: any;
  public isPaid: boolean;
  public isExpired: boolean;
  public mobileNumber: string;
  public email: string;
  public billUserName: string;
  public status: string;
  public templateVersion: string;
  public afterDueDateValue: string;
  public dateAfterDue: Date;
  public billNo: string;
  public address: string;
  public isOptInEmail: boolean;
  public pdfVersionPath: string;
  public accountName: string;
  public accountEmail: string;
  public totalAmount: string;
  public billType: string;
  public broadcastMessage: BroadcastMessages[];
  public paidAmount: string;
  public cutOffDateVerbiage: string;
  public totalDueNow: string;
  public totalDueAmount: number;
  public utilityConsent: string;
  public paymentChannel: string;
  public cutOffDate: string;
  public prevBalCutOffDate: Date;
  public afterDueDateAmt: number;
  public isCutOffDateExpired: boolean;

  public allowPartialPayment: string;

  // -------- For COC---------
  public pdfPath: string;
  public pdfName: string;
  public containerName: string;
  public utilityLabels: UtilityLabelConfiguration;

  constructor() {
    this.billId = null;
    this.logo = null;
    this.merchant = null;
    this.billNo = null;
    this.utility = null;
    this.consumer = null;
    this.meterNumber = null;
    this.contactNumber = null;
    this.previousBalance = null;
    this.billDate = null;
    // this.lastPayment = null;
    this.accountNumber = null;
    this.afterDueDate = null;
    this.dueAmount = null;
    this.payments = null;
    this.isPaid = null;
    this.mobileNumber = null;
    this.email = null;
    this.billUserName = null;
    this.status = null;
    this.templateVersion = null;
    this.afterDueDateValue = null;
    this.dateAfterDue = new Date();
    this.address = null;
    this.isOptInEmail = false;
    this.pdfVersionPath = null;
    this.broadcastMessage = new Array<BroadcastMessages>();
    this.paidAmount = null;
    this.cutOffDate = null;
    this.cutOffDateVerbiage = null;
    this.isCutOffDateExpired = false;

    this.totalDueNow = null;
    this.totalDueAmount = null;
    this.utilityConsent = null;
    this.paymentChannel = null;
    this.prevBalCutOffDate = new Date();
    this.afterDueDateAmt = null;

    this.pdfName = null;
    this.pdfPath = null;
    this.containerName = null;
    this.utilityLabels = null;

    this.allowPartialPayment = null;
  }
}

export class UtilityLabelConfiguration {
  public accountNumber: string;
  // public lastPaid: string;
  // public lastPayment: string;
  public previousBalance: string;
  public dueDate: string;
  public AfterDueDate: string;
  public billDate: string;
  public connection: string;
  public assetName: string;
  public dueAmount: string;
  public billAmount: string;
  public billNumber: string;
  public afterDueDate: string;
  constructor() {
    this.accountNumber = null;
    this.previousBalance = null;
   // this.lastPayment = null;
    this.dueDate = null;
    this.AfterDueDate = null;
    this.billDate = null;
    this.connection = null;
    this.assetName = null;
    this.dueAmount = null;
    this.billAmount = null;
    this.billNumber = null;
    this.afterDueDate = null;
  }
}
