import { ConvinienceFee } from 'app/_shared/model/ConvinienceFee.model';

export class ConsumerBill {
	public id: string;
	public consumerId: string;
	public accountNumber: string;
	public billDate: string;
	public dueDate: string;
	public afterDueDate: string;
	public dueAmount: number;
	public merchantId: string;
	public lastPaidDate: string;
	public billNumber: string;
	public lastPaidAmount: number;
	public utilityId: string;
	public utilityName: string;
	public merchantName: string;
	public templateVersion: string;
	public isPaid: boolean;
	public paidOn: string;
	public logo: string;
	public description: string;
	public email: string;
	public billId: string;
	public billUserName: string;
	public afterDueDateValue: Date;
	public mobile: string;
	public address: string;
	public convinienceFee: ConvinienceFee;
	public billType: string;
  public totalAmount: string;
  public previousBalance: number;
  public totalDueNow: string;
  public paidAmount: string;
  public afterDueAmt: number;
  public isExpired: boolean;
  public totalDueAmount: number;
  public cutOffDateVerbiage: string;
  public isCutOffDateExpired: boolean;
  public allowPartialPayment : string

	// -------- For COC---------
	public pdfPath: string;
	public pdfName: string;
	public containerName: string;
	constructor() {
		this.id = null;
		this.consumerId = null;
		this.accountNumber = null;
		this.billDate = null;
		this.dueDate = null;
		this.logo = null;
		this.afterDueDate = null;
		this.billNumber = null;
		this.dueAmount = null;
		this.merchantId = null;
		this.lastPaidDate = null;
		this.lastPaidAmount = null;
		this.utilityId = null;
		this.utilityName = null;
		this.merchantName = null;
		this.templateVersion = null;
		this.isPaid = null;
		this.paidOn = null;
		this.billId = null;
		this.mobile = null;
		this.address = null;
		this.convinienceFee = new ConvinienceFee();
		this.billType = null;
    this.totalAmount = null;
    this.previousBalance = null;
    this.totalDueNow = null;
    this.paidAmount = null;
    this.afterDueAmt = null;
    this.cutOffDateVerbiage = null;
    this.isCutOffDateExpired = false;
		// -------- For COC---------
		this.pdfPath = null;
		this.pdfName = null;
		this.containerName = null;

		this.allowPartialPayment = null;
	}
}
