import { Component, OnInit } from '@angular/core';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { RegisterUtility } from 'app/_shared/model/register-utility';
import { BillFilter } from '../_shared/model/bill-filter';
import { AccountService } from 'app/account/account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'app/_core/common.service';
import { OptInEBills, DeviceInfo } from '../_shared/model/opt-in-e-bills';
import { BillsService } from '../bills.service';
import { BsModalRef } from 'ngx-bootstrap';
import { Jsonp } from '@angular/http';
import { Ng2DeviceService } from 'ng2-device-detector';
declare var SignaturePad: any;

@Component({
  selector: 'app-subscribed-ebills',
  templateUrl: './subscribed-ebills.component.html',
  styleUrls: ['./subscribed-ebills.component.css'],
})
export class SubscribedEbillsComponent implements OnInit {
  paginationVM = new PaginationVM<RegisterUtility, BillFilter>();
  pageNumber: number;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  previousPageSize = 0;
  utilities: RegisterUtility[];
  billFilter: BillFilter;
  consumerId: string;
  optInBill: RegisterUtility;
  optInForEbill: OptInEBills;
  bsModalRef: BsModalRef;
  signaturePad: any;
  canvas: HTMLElement;
  showMessage: boolean;
  deviceData: DeviceInfo;
  deviceInfo: any;

  constructor(
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private billService: BillsService,
    private deviceService: Ng2DeviceService,
    private _jsonp: Jsonp

  ) {
    this.utilities = new Array<RegisterUtility>();
    this.consumerId = this.commonService.getConsumerUserId();
    this.paginationVM = <PaginationVM<RegisterUtility, BillFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: 'Id',
      sortType: 'desc',
      records: this.utilities,
      filters: this.billFilter,
    };
    this.optInBill = new RegisterUtility();
    this.optInForEbill = new OptInEBills();
    this.showMessage = false;
    this.deviceData = new DeviceInfo();

  }

  ngOnInit() {
    this.getSubscribedEbills();
    this.canvas = document.getElementById('signature-pad');
    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: 'rgb(255, 255, 255)',
    });
    this.GetDeviceDetails();
  }

  clearSignature() {
    this.signaturePad.clear();
    this.showMessage = false;
  }

  /* getting specific consumer subscribed e-bills list and registered utilities*/
  getSubscribedEbills() {
    this.spinner.show();
    const previousPageSize = this.paginationVM.records.length;
    this.accountService
      .getUtilities(this.consumerId, this.paginationVM)
      .subscribe(
        (res) => {
          this.utilities = [];
          this.spinner.hide();
          if (res) {
            this.paginationVM = res;
            this.utilities = new Array<RegisterUtility>();
            this.utilities = res.records;
            if (this.increase) {
              this.showingPage =
                this.paginationVM.pageNumber === 0
                  ? this.paginationVM.records.length
                  : this.showingPage + this.paginationVM.records.length;
            } else if (this.decrease) {
              this.showingPage =
                this.paginationVM.pageNumber === 0
                  ? this.paginationVM.records.length
                  : this.showingPage - previousPageSize;
            } else {
              this.showingPage = this.paginationVM.records.length;
            }
            this.oldPageLength = this.paginationVM.records.length;
            this.pageNumber =
              res.pageNumber === undefined ? 1 : res.pageNumber + 1;
            this.paginationVM.pageNumber =
              this.pageNumber === 1 ? 0 : this.pageNumber - 1;
            this.increase = false;
            this.decrease = false;

          }
        },
        (err) => {
          this.spinner.hide();
        }
      );
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getSubscribedEbills();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getSubscribedEbills();
    }
  }

  /* open unsubscribed e-bill modal */
  openUnsubscribeModal(item: RegisterUtility, modal) {
    modal.show();
    this.showMessage = false;
    this.signaturePad.clear();
    this.optInBill = item;
  }

  /* open subscribed e-bill modal */
  openSubscribeModal(item: RegisterUtility, modal) {
    this.signaturePad.clear();
    modal.show();
    this.optInBill = item;
  }

  /* subscribe & unsubscribe e-bill */
  subscribe(modal, accountNumber, utilityId, merchantId, consumerEmail, isOptIn, conscent) {
    if (!this.signaturePad.isEmpty()) {
      this.spinner.show();
      this.optInForEbill = {
        id: null,
        accountNumber: accountNumber,
        consumerId: this.consumerId,
        utilityId: utilityId,
        isOptInEmail: isOptIn,
        consumerEmail: consumerEmail,
        signatureData: this.signaturePad.toDataURL('image/png'),
        deviceInfo: this.deviceData,
        utilityConsent: conscent,
      };
      this.billService
        .OptInForEbills(this.optInForEbill, merchantId)
        .subscribe((res) => {
          if (res) {
            modal.hide();
            this.getSubscribedEbills();
            this.spinner.hide();
          }
          (err) => {
            this.spinner.hide();
          };
        });
    } else {
      this.showMessage = true;
    }

  }

  //unsubscribe api
  unSubscribe(modal, accountNumber, utilityId, merchantId, consumerEmail, isOptIn, conscent) {
    this.spinner.show();
    this.optInForEbill = {
      id: null,
      accountNumber: accountNumber,
      consumerId: this.consumerId,
      utilityId: utilityId,
      isOptInEmail: isOptIn,
      consumerEmail: consumerEmail,
      signatureData: this.signaturePad.toDataURL('image/png'),
      deviceInfo: this.deviceData,
      utilityConsent: conscent,
    };
    this.billService
      .OptInForEbills(this.optInForEbill, merchantId)
      .subscribe((res) => {
        if (res) {
          modal.hide();
          this.getSubscribedEbills();
          this.spinner.hide();
        }
        (err) => {
          this.spinner.hide();
        };
      });
  }

  /* close e-bill moadl */
  closeOptIn(modal) {
    modal.hide();
    this.signaturePad.clear();
    this.showMessage = false;
    this.getSubscribedEbills();
  }

  //get device details
  GetDeviceDetails() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const location = position.coords;
        this.deviceData.latitude = location.latitude.toString();
        this.deviceData.longitude = location.longitude.toString();
      });
    }
    this._jsonp.get('//api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK').subscribe(response => {
      const data = response.json();
      this.deviceData.iPaddress = data.ip;
      this.deviceData.operatingSystem = data.os;
    });
    this.deviceData.oSVersion = this.deviceInfo.os_version;
    this.deviceData.browserVersion = this.deviceInfo.browser_version;
    this.deviceData.browserName = this.deviceInfo.browser;
    this.deviceData.device = this.deviceInfo.device;
  }

  //view ebill print bill
  viewEbillDetails(ebillId: string, merchantId: string) {
    const urls = "bills/ebill-data-view?ebillId=" + ebillId + "&merchantId=" + merchantId;
    window.open(urls);
  }
}
