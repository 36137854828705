export class PaymentFilter {
    public  utilityId: string;
	public  from: Date;
	public  to: Date;
	public  status: string;

	constructor() {
		this.utilityId = null;
		this.from = null;
		this.to = null;
		this.status = null;
	  }
}
