import { BillsService } from 'app/bills/bills.service';
import { Component, OnInit } from '@angular/core';
import { Receipt } from '../model/receipt-model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
	selector: 'app-payment-receipt-modal',
	templateUrl: './payment-receipt-modal.component.html',
	styleUrls: [ './payment-receipt-modal.component.css' ]
})
export class PaymentReceiptModalComponent implements OnInit {
	receipt: Receipt;

	// payment receipt modal
	transactionID: string;
	paymentDateTime: Date;
	transactionState: string;
	amount: number;
	cardType: string;
	maskedPan: string;
	currencyCode: string;
	accountNumber: string;
	billUserName: string;
	billNumber: string;
	billCycle: string;
	billDate: Date;
	constructor(
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private billService: BillsService
	) {
		this.receipt = new Receipt();
	}

	ngOnInit() {
		// this.receipt = {
		// 	transactionID: this.transactionID,
		// 	paymentDateTime: this.paymentDateTime,
		// 	transactionState: this.transactionState,
		// 	amount: this.amount / 100,
		// 	cardType: this.cardType,
		// 	maskedPan: this.maskedPan,
		// 	currencyCode: this.currencyCode,
		// 	accountNumber: this.accountNumber,
		// 	billUserName: this.billUserName,
		// 	billNumber: this.billNumber,
		// 	billCycle: this.billCycle,
		// 	billDate: this.billDate,
		// 	errorCode: ''
		// };
		// this.receipt.amount.toString().includes('.') === false ? this.receipt.amount + '.00' : this.receipt.amount;
	}

	hide(modal: BsModalRef) {
    this.billService.updateBillDetailsList(true);
		modal.hide();
	}
}
