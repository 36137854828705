import { ToastrService } from "ngx-toastr";
import { CommonService } from "./../_core/common.service";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AccountService } from "app/account/account.service";
import { ConsumerDetails } from "app/_shared/model/consumer-details";
import { Consumer } from "app/account/_shared/model/consumer";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { TermsAndConditionsComponent } from "app/_shared/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPalicyComponent } from "app/_shared/privacy-palicy/privacy-palicy.component";
import { ConsumerNotifications } from "app/notification/_shared/model/consumer-notifications";
import { NotificationService } from 'app/notification/notification.service';
declare var $: any;

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styles: []
})
export class MainComponent implements OnInit {
  userId: string;
  user: Consumer;
  notifications: ConsumerNotifications[];
  sessionId: string;
  bsModalRef: BsModalRef;
  unreadNotificationCount: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private notificationService: NotificationService
  ) {
    this.user = this.commonService.getConsumerData();
    this.sessionId = this.commonService.getSession();
    this.notifications = new Array<ConsumerNotifications>();
    this.unreadNotificationCount = 0;
  }

  ngOnInit() {
    this.notificationService.notificationUpdate.subscribe(isReload => {
      if (isReload) {
        this.getNotifications();
        this.notificationService.updateNotificationList(false);
      }
    });

    this.getNotifications();
    //scroll
    $(".height-terms-condition").each(function() {
      var myHeight = $(window).height() - 190;
      $(this).slimscroll({
        height: myHeight
      });
    });

    if ($(window).innerWidth() <= 767) {
      $(".mobile-close").hide();
      $(".sidebar-left").removeClass("left-55");
      $(".sidebar-left").addClass("small-sidebar");
      $(".main-section").removeClass("open-sidebar");
      $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").hide();
      $(".side-menu ul li a").addClass("text-center");

      $(".btn-toggle-sidebar").click(function() {
        $(".mobile-close").show();
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
        $(".side-menu ul li a").removeClass("text-center");
      });
      $(".mobile-close").click(function() {
        $(this).hide();
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
        $(".small-sidebar .side-menu ul li a").addClass("text-center");
      });
    } else {
      $(".btn-toggle-sidebar").click(function() {
        $(".sidebar-left").toggleClass("left-55");
        $(".sidebar-left").toggleClass("small-sidebar");
        $(".main-section").toggleClass("open-sidebar");
        $(".profile-basic-info, .side-menu ul li a span.menu-name, .bottom-sidebar, .profile-information .dropdown").toggle();
      });
    }

    this.accountService.UserUpdate.subscribe(isReload => {
      if (isReload) {
        this.getUser();
        this.accountService.showRegMessageOnAddUtility(false);
      }
    });
    this.getUser();
  }

  /* get user details on email*/
  getUser() {
    // this.spinner.show();
    this.accountService.GetProfile(this.user.email).subscribe(res => {
      if (res) {
        this.user = res;
        // this.spinner.hide();
      }
    });
    this.spinner.hide();
  }

  /* user logout */
  logOut() {
    this.spinner.show();
    this.accountService.LogOut(this.sessionId).subscribe(res => {
      if (res) {
        if(res.modifiedCount === 1) {
          sessionStorage.clear();
          this.router.navigate(["/account/login"]);
        }
      }
    });
    this.spinner.hide();
  }

  /* open terms and consition modal */
  viewTermsAndConditions() {
    this.bsModalRef = this.modalService.show(TermsAndConditionsComponent, {
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }

  /* open privacy policy modal */
  viewPrivacyPolicy() {
    this.bsModalRef = this.modalService.show(PrivacyPalicyComponent, {
      class: "modal-lg"
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }

  /* get consumers notifications */
  getNotifications() {
    // this.spinner.show();
    this.notificationService.GetNotificatins(this.user.id).subscribe(res => {
      if (res) {
        this.notifications = res;
        this.unreadNotificationCount = this.notifications.length > 0 ? this.notifications[0].unreadNotiCount : 0;
        // this.spinner.hide();
      }
    });
    this.spinner.hide();
  }

  /* update notification for read and unread*/
  updateNotification(notificationId) {
    this.spinner.show();
    this.notificationService.UpdateNotification(notificationId).subscribe(res => {
      if (res) {
        this.notificationService.updateNotificationList(true);
        this.spinner.hide();
        this.router.navigate(['/notification/notiffications']);
      }
    });
    this.spinner.hide();
  }
}
