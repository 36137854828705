import { SubsribedUtilities } from './subsribed-utilities';

export class NewUtility {
    merchantId: string;
	utilityId: string;
    accountNumber: string;
    subcribedUtilities: SubsribedUtilities;
    constructor() {
        this.merchantId = null;
        this.utilityId = null;
        this.accountNumber = null;
        this.subcribedUtilities = new SubsribedUtilities();
    }
}
