import { Consumer } from './../account/_shared/model/consumer';
import { ConsumerDetails } from './../_shared/model/consumer-details';
import { Injectable } from '@angular/core';
import { Admin } from 'app/administration/_shared/model/admin';
import { Observable, BehaviorSubject } from 'rxjs';
import { AdminModel } from 'app/administration/_shared/model/admin-model';
import { SiteVisit } from 'app/_shared/model/site-visit';
import { DataService } from './data.service';
import { Ng2DeviceService } from 'ng2-device-detector';
import { Jsonp } from '@angular/http';
import { AccountService } from 'app/account/account.service';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { SessionOutComponent } from 'app/_shared/session-out/session-out.component';
import { LatestBill } from 'app/bills/_shared/model/latest-bill';
import { CardExpired } from 'app/payments/_shared/model/card-expired';
import { UpdateCardDetailsComponent } from 'app/payments/update-card-details/update-card-details.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  deviceInfo: any;
  browserDetails: SiteVisit;
  consumer: Consumer;
  sessionId: string;
  bsModalRef: BsModalRef;

  constructor(
    private dataService: DataService,
    private accountService: AccountService,
    private router: Router,
    private modalService: BsModalService
  ) {
    this.consumer = this.getConsumerData();
    this.browserDetails = this.getBrowserDetails();
    this.sessionId = this.getSession();
  }

  getConsumerData(): Consumer {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  getConsumerUserId(): string {
    return this.getConsumerData().id;
  }

  getConsumerUserEmail(): string {
    return this.getConsumerData().email;
  }

  getSession(): string {
    return sessionStorage.getItem('sessionId');
  }

  getAdminData() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  getBrowserDetails() {
    return JSON.parse(sessionStorage.getItem('browserDetails'));
  }

  siteVisit(merchantId: string, utilityId: string, accountNumber: string): Observable<SiteVisit> {
    const consId = this.getConsumerUserId();
    this.browserDetails.consumerId = consId !== null ? consId : null; //this.consumer != null ? this.consumer.id : '';
    this.browserDetails.consumerEmail = this.getConsumerUserEmail() !== null ? this.getConsumerUserEmail() : null;
    this.browserDetails.merchantId = merchantId;
    this.browserDetails.utilityId = utilityId;
    this.browserDetails.accountNumber = accountNumber;
    this.browserDetails.sessionId = this.sessionId;
    return this.dataService.post<SiteVisit, SiteVisit>('consumers/sitevisit', this.browserDetails);
  }

  siteVisitBeforelogin(merchantId: string, utilityId: string, accountNumber: string, browserDetails: SiteVisit,email: string): Observable<SiteVisit> {
    browserDetails.consumerId =  null;
    browserDetails.consumerEmail = email !== '' ? email : null;
    browserDetails.merchantId = merchantId;
    browserDetails.utilityId = utilityId;
    browserDetails.accountNumber = accountNumber;
    browserDetails.sessionId = null;
    return this.dataService.post<SiteVisit, SiteVisit>('consumers/sitevisit', browserDetails);
  }


  formatDate(value) {
    return value.getMonth() + 1 + '' + value.getDate() + '' + value.getFullYear();
  }

  forceLogout() {
    this.accountService.LogOut(this.sessionId).subscribe(res => {
      if (res) {
        this.bsModalRef = this.modalService.show(SessionOutComponent, { class: 'modal-md', backdrop: 'static' });
      }
    });
  }

  viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, merchantName, utilityName, tempVersion) {
    if (utilityName === 'MRWC Water Sales' && merchantName === 'Meansville-Riley Water Company, Inc' && tempVersion === 'V1') {
      const marlboro =
        '/bill/bill-mrwc?billId=' + billId + '&merchantId=' + merchantId + '&utilityId=' + utilityId + '&accountNumber=' + accountNumber;
      return marlboro;
    } else if (utilityName === 'MRWC Water Sales' && merchantName === 'Meansville-Riley Water Company, Inc' && tempVersion === 'V2') {
      const marlboro =
        '/bill/bill-mrwc-v2?billId=' + billId + '&merchantId=' + merchantId + '&utilityId=' + utilityId + '&accountNumber=' + accountNumber;
      return marlboro;
    }
  }

  viewPdfFromCloud(pdfName: string, containerName: string) {
    const path = '/bill/pdf-bills?pdfName=' + pdfName + '&containerName=' + containerName;
    return path;
  }


  currentDateFormat(y: number,m: number,d: number,h: number, min: number, sec: number, ms: number) {
    const date = new Date();
    date.setFullYear(y);
    date.setMonth(m);
    date.setDate(d);
    date.setHours(h);
    date.setMinutes(min);
    date.setSeconds(sec);
    date.setMilliseconds(ms);
    return date;
  }

  GetExpiredCardDetails(consumerId: string): Observable<CardExpired[]> {
    return this.dataService.get<CardExpired[]>(
      `scheduledpayments/${consumerId}/expiredcards`
    );
  }

  checkCardExpired(consumerId) {
    this.GetExpiredCardDetails(consumerId).subscribe((res) => {
      if (res) {
        const expiredcards = res.filter((x) => x.isExpired)[0];
        if (expiredcards != null) {
          const initialState = {
            accountNumber: expiredcards.accountNumber,
            cardExpiry: expiredcards.cardExpiry
          };
          this.bsModalRef = this.modalService.show(UpdateCardDetailsComponent, {
            initialState,
            class: 'modal-lg'
          });
        }
      }
    });
  }
}
