export class ItemId {
    public id:  string; 
    public name: string;

    constructor()
    {
        this.id = null;
        this.name = null;
    }
}
