import { Consumer } from 'app/account/_shared/model/consumer';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/_core/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from '../account.service';
import { ConsumerDetails } from 'app/_shared/model/consumer-details';
import { Router } from '@angular/router';
import { Constant, MaxLengthConstants } from 'app/_shared/constant';
declare var $: any;
import { TextMaskModule } from 'angular2-text-mask';


@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html'

})
export class EditProfileComponent implements OnInit {
  user: Consumer;
  showImage: boolean;
  gender: boolean;

  namePattern = Constant.NAME_REGEXP;
  phonePattern = Constant.US_PHONE;
  maxLengthPhone = MaxLengthConstants.Phone;
  maxLengthCity = MaxLengthConstants.City;
  zipPattern = Constant.ZIP_REGEXP;
  maxLengthAddress = MaxLengthConstants.Address
  zipMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/ ,'-', /\d/, /\d/, /\d/, /\d/];
  sessionUser: Consumer;

  constructor( private commonService: CommonService, private spinner: NgxSpinnerService, private accountService: AccountService , private router: Router, private toastr: ToastrService) {
    this.sessionUser = this.commonService.getConsumerData();
    this.gender = false;
    this.user = new Consumer();
   // this.user.gender = 'Male';
  }

  ngOnInit() {
    $('.btn-switch-gender').click(function(){
      $('.btn-switch-gender.active').removeClass('active');
      $(this).addClass('active');
    });


    $('.bill-box .title-bill, .bill-box .logo, .bill-box .full-view').click(function(){
      $('.all-bills').hide();
      $('.single-bill').show();
    });

    this. getUser();
  }

  onKey(event: any) { // without type info
   
    let val=event.target.value;
    if(val.length==6 && event.keyCode!=8){
      this.user.zip = val.match(/.{1,5}/g).join('-')
    }
  }
/*Get Consumer Profile */
  getUser() {
		this.spinner.show();
		this.accountService.GetProfile(this.sessionUser.email).subscribe((res) => {
			if (res) {
				this.user = res;
				this.spinner.hide();
			}
		});
		this.spinner.hide();
  }
/*Update Consumer Profile */
  updateUser(isValid: boolean) {
    this.gender = false;
		if (isValid && this.user.gender) {
      this.spinner.show();
			this.accountService.UpdateUser(this.user).subscribe(
				(data) => {
					if (data) {
            this.accountService.updateProfileOnNavbar(true);
            this.router.navigate([ '/dashboard' ]);
            this.spinner.hide();
					}
				},
				(err) => {this.spinner.hide();}
			);
    }
    else {
      if(this.user.gender === null || this.user.gender === undefined) {
        this.gender = true;
      }
    }
  }

  genderValue(gender) {
    this.user.gender = gender;
    this.gender = false;
  }

  onImageFileChange(evt: any) {
    this.spinner.show();
    const reader = new FileReader();
    if (evt.target.files && evt.target.files.length > 0) {
      const file = evt.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.user.profilePhoto = 'data:image/jpg;base64,' + reader.result.toString().split(',')[1];
        this.showImage = true;
        this.spinner.hide();
      };
    }
  }

  onImageFileClick(img: any) {
    img.target.value = '';
  }

}
