import { AccountService } from "./../account.service";
import { ChangePassword } from "./../../_shared/model/change-password";
import { Component, OnInit, TemplateRef } from "@angular/core";
import { MaxLengthConstants, Constant } from "app/_shared/constant";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"]
})
export class ChangePasswordComponent implements OnInit {
  password: ChangePassword;
  
  passwordMismatch: boolean;
  modalRef: BsModalRef;
  public loading = false;
  differentPassword:boolean;

  maxlengthPassword = MaxLengthConstants.Password;
  passwordPattern = Constant.PASSWORD_REGEXP;
  isCancelBtn: boolean;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.password = new ChangePassword();
	this.passwordMismatch = true;
  this.isCancelBtn = true;
  this.differentPassword = true;
  }

  ngOnInit() {
    if (this.router.url === "/dashboards/change-password") {
      this.isCancelBtn = false;
    }
  }

  validatePassword(change) {
    
      if (change && change.password !== change.confirmPassword && change.confirmPassword !== change.password) {
        this.passwordMismatch = false;
      } else {
        this.passwordMismatch = true;
      }
    
      if(this.password.oldPassword === this.password.password||this.password.password === this.password.oldPassword)
      {
        this.differentPassword = false;
        
      }
      else{
        this.differentPassword = true;
      }
      
  }

  changePassword(isValid: boolean) {
    if (isValid && this.passwordMismatch && this.differentPassword) {
      this.spinner.show();
      this.accountService.ChangePassword(this.password).subscribe(
        res => {
          if (res) {
            this.accountService.updatePasswordMessage(true);
            this.router.navigate(["/dashboard"]);
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        },
        err => {
          if (err.error[0].code === "PasswordMismatch") {
            this.spinner.hide();
            this.toastr.error("The current password is invalid. Enter correct password", "", {
              timeOut: 3000
            });
          }
          this.spinner.hide();
        }
      );
    } else {
    }
  }
}
