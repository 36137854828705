import { Pipe, PipeTransform } from '@angular/core';
import { transition } from '@angular/animations';
import { stringify } from '@angular/compiler/src/util';

@Pipe({
  name: 'transactionView'
})
export class TransactionViewPipe implements PipeTransform {
  transactionId: string;
  transform(transactionView: any, args?: any): any {
    var transactionIdToreplace = transactionView.substring(0,(transactionView.length)-4);
    var lastFourChar = transactionView.substring((transactionView.length)-4,transactionView.length);
    return (new Array(transactionIdToreplace.length + 1).join('X') + lastFourChar);
  }

}
