import { User } from 'app/_shared/model/user.model';
class Result {
  contentTypes: string[];
  declareType: string;
  formatters: string[];
  statusCode: number;
  value: string;
  status: number;
  constructor() {
    this.contentTypes = null;
    this.declareType = null;
    this.formatters = null;
    this.statusCode = null;
    this.status = null;
    this.contentTypes = null;
  }
}
export class Login {
  result: Results;
  token: Token;
}

class Results {
  company: string;
  customer: string;
  user: User;

  constructor() {
    this.company = null;
    this.customer = null;
    this.user = new User();
  }
}

class Token {
  asyncState: string;
  creationOptions: number;
  exception: string;
  isCanceled: boolean;
  isCompleted: boolean;
  isCompletedSuccessfully: boolean;
  isFaulted: false;
  result: Result;

  constructor() {
    this.asyncState = null;
    this.creationOptions = null;
    this.exception = null;
    this.isCanceled = null;
    this.isCompleted = null;
    this.isCompletedSuccessfully = null;
    this.isFaulted = null;
    this.result = new Result();
  }
}
