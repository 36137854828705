import { NewsList } from "./../_shared/model/news-list";
import { Component, OnInit } from "@angular/core";
import { AccountService } from "app/account/account.service";
import { User } from "app/_shared/model/user.model";
import { NgForm } from "@angular/forms";
import { Login } from "app/_shared/model/login.model";
import { MaxLengthConstants, Constant } from "app/_shared/constant";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { TermsAndConditionsComponent } from "app/_shared/terms-and-conditions/terms-and-conditions.component";
import { PrivacyPalicyComponent } from "app/_shared/privacy-palicy/privacy-palicy.component";
import { ContactUs } from "app/_shared/model/contact-us";
import { SiteVisit } from "app/_shared/model/site-visit";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "environments/environment";
declare var $: any;
@Component({
  selector: "app-static",
  templateUrl: "./static.component.html",
  styles: [],
})
export class StaticComponent implements OnInit {
  user: User;
  data: Login;
  public loading = false;
  linkName: string;
  contactUs: ContactUs;
  siteVisit: SiteVisit;
  deviceInfo: any;

  userNameLength = MaxLengthConstants.UserName;
  userNamePattern = Constant.USERNAME;
  passwordLength = MaxLengthConstants.Password;
  passwordPattern = Constant.PASSWORD_REGEXP;
  emailLength = MaxLengthConstants.Email;
  emailPattern = Constant.EMAIL_REGEXP;
  phonePattern = Constant.PHONE_REGEXP;
  phoneLength = MaxLengthConstants.Phone;
  bsModalRef: BsModalRef;
  urls: string;
  newsList: NewsList[];
  itemList: NewsList;
  list: NewsList[];

  merchantUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private accountService: AccountService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.user = new User();
    this.data = new Login();
    this.contactUs = new ContactUs();
    this.siteVisit = new SiteVisit();
    this.itemList = new NewsList();
    this.newsList = new Array<NewsList>();
  }

  ngOnInit() {
    if (screen.width <= 699) {
      window.location.href = environment.mobileAppUrl;
    }
    this.news();
    //scroll
    $(".height-terms-condition").each(function () {
      var myHeight = $(window).height() - 190;
      $(this).slimscroll({
        height: myHeight,
      });
    });

    $("#header-slider.owl-carousel").owlCarousel({
      loop: true,
      dots: true,
      nav: false,
      items: 1,
      autoplayTimeout: 5000,
      animateOut: "fadeOut",
      autoplay: true,
    });

    $("#utilities-slider.owl-carousel").owlCarousel({
      loop: true,
      dots: false,
      nav: true,
      margin: 20,
      autoplay: false,
      center: true,
      navText: ["<i class='fas fa-angle-left'></i>", "<i class='fas fa-angle-right'></i>"],
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 3,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 5,
        },
      },
    });

    $("#office-slider.owl-carousel").owlCarousel({
      loop: true,
      dots: false,
      nav: true,
      margin: 20,
      autoplay: true,
      //stop perticular time interval

      autoplayTimeout: 2000,
      pause: "hover",
      stopOnHover: true,
      navText: ["<i class='fas fa-angle-left'></i>", "<i class='fas fa-angle-right'></i>"],
      responsive: {
        0: {
          items: 1,
        },
        576: {
          items: 2,
        },
        992: {
          items: 3,
        },
        1200: {
          items: 4,
        },
      },
    });

    this.router.events.subscribe((s) => {
      if (s instanceof NavigationEnd) {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
          const element = document.querySelector("#" + tree.fragment);
          if (element) {
            element.scrollIntoView(true);
          }
        }
      }
    });

    //tooltip
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  }
  ngAfterViewInit() {
    $("#newsTicker1").breakingNews();
  }

  /* getting news data from xml and massage the news data */
  news() {
    var xml = new XMLHttpRequest();
    xml.open("GET", "../assets/data/news.xml", false);
    xml.send();
    var xmlData = xml.responseXML;
    if (xmlData != null) {
      xmlData = new DOMParser().parseFromString(xml.responseText, "text/xml");
    }
    var main = xmlData.getElementsByTagName("main");
    var news = main[0].getElementsByTagName("news")[0].children;
    for (let index = 0; index < news.length; index++) {
      this.itemList = new NewsList();
      this.itemList.news = news[index].innerHTML;
      this.newsList.push(this.itemList);
    }
  }

  /* call div ids on navigation bar */
  onAnchorClick() {
    this.route.fragment.subscribe((f) => {
      this.linkName = f;
      const element = document.querySelector("#" + f);
      if (element) {
        element.scrollIntoView(true);
      }
    });
  }

  /* open terms and conditions modal */
  viewTermsAndConditions() {
    this.bsModalRef = this.modalService.show(TermsAndConditionsComponent, {
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }

  /* open privacy policy modal */
  viewPrivacyPolicy() {
    this.bsModalRef = this.modalService.show(PrivacyPalicyComponent, {
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }

  /* send contact us mail to sender and receiver */
  sendQuery(form: NgForm) {
    if (form.valid) {
      this.spinner.show();
      this.accountService.postContact(this.contactUs).subscribe(
        (res) => {
          this.spinner.hide();
          if (res != null) {
            this.toastr.success("Your query has been submitted successfully.", "Thank you!", {
              timeOut: 3000,
            });
          }
          form.resetForm();
        },
        (err) => {
          this.toastr.error("unable to contact.", "", {
            timeOut: 3000,
          });
          this.spinner.hide();
        }
      );
    }
  }

  openMerchant() {
    this.urls = environment.merchantUrl;
  }

  /* reset contact us form */
  cancel(form: NgForm) {
    form.resetForm();
  }
}
