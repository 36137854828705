import { RegisterUtility } from './../../_shared/model/register-utility';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConsumerUtility } from './../../_shared/model/consumer-utility';
import { ConsumerDetails } from './../../_shared/model/consumer-details';
import { CommonService } from './../../_core/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UtilityMaster } from './../../_shared/model/utility-master';
import { AccountService } from 'app/account/account.service';
import { Router } from '@angular/router';
import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ModalContentComponent } from 'app/_shared/modal-content/modal-content.component';
import { Merchant } from 'app/administration/_shared/model/merchant';
import { Utility } from 'app/_shared/model/utility';
import { UtilityVM } from 'app/_shared/model/utility-vm';
import { Consumer } from '../_shared/model/consumer';
import { NewUtility } from '../_shared/model/new-utility';
import { Constant } from 'app/_shared/constant';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { BillSearchResult } from 'app/bills/_shared/model/bill-search-result';
import { BillFilter } from 'app/bills/_shared/model/bill-filter';
import { SubsribedUtilities } from '../_shared/model/subsribed-utilities';
import { environment } from 'environments/environment';

export interface DialogData {
  id: string;
  message?: string;
}

@Component({
  selector: 'app-add-utility',
  templateUrl: './add-utility.component.html'
})
export class AddUtilityComponent implements OnInit {
  hideBack = true;
  accountNumber: string;
  selectUtility: any;
  masterList: UtilityMaster[];
  utility: UtilityMaster;
  consumer: Consumer;
  consumerId: string;
  merchantList: Merchant[];
  paginationVM = new PaginationVM<RegisterUtility, BillFilter>();
  bsModalRef: BsModalRef;
  registerUtility: RegisterUtility;
  registeredUtilities: Utility[];
  newUtility: NewUtility;
  utilities: RegisterUtility[];
  billFilter: BillFilter;
  invalidAccNo = false;
  selectedMerchantId: string;
  accountRegisterd = false;
  accountPattern = Constant.NUMBER_REGEXP;
  pageNumber: number;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  previousPageSize = 0;
  subcribeUtility: SubsribedUtilities;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private toastr: ToastrService,
  ) {
    this.registeredUtilities = new Array<Utility>();
    this.registerUtility = new RegisterUtility();
    this.consumerId = this.commonService.getConsumerUserId();
    this.utilities = new Array<RegisterUtility>();
    this.merchantList = new Array<Merchant>();
    this.newUtility = new NewUtility();
    this.subcribeUtility = new SubsribedUtilities();
    this.paginationVM = <PaginationVM<RegisterUtility, BillFilter>>{
      pageNumber: 0,
      pageSize: 5,
      totalCount: 0,
      sortBy: 'Id',
      sortType: 'desc',
      records: this.utilities,
      filters: this.billFilter
    };
  }

  ngOnInit() {

    this.accountService.successMsgRegistration.subscribe(msg => {
      if (msg) {
       
        this.accountService.showRegMessageOnAddUtility(false);
      }
    });
    this.getMerchants();
    this.getUtilities();
    if (this.router.url === '/account/addutility') {
      this.hideBack = false;
    }

  }

  resetAccountNumber(acc) {
    if (acc === '' || acc === null) {
      this.accountRegisterd = false;
      this.invalidAccNo = false;
    }
  }
/*Get all merchant list*/
  getMerchants() {
    const host = window.location.host === environment.hostUrl ? null : window.location.host;
    this.spinner.show();
    this.accountService.getMerchantsByHost(host).subscribe(
      res => {
        if (res) {
          this.merchantList = res;
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }
/*Get list of utilities registered for perticular merchant*/
  getUtilitiesFromMerchantId(merchantId: string) {
    this.spinner.show();
    this.selectedMerchantId = merchantId;
    this.accountService.getUtilitiesFromMerchant(merchantId).subscribe(
      res => {
        if (res) {
          this.registeredUtilities = res;
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }
/*Get all the utilities*/
  getUtilities() {
    this.spinner.show();
    const previousPageSize = this.paginationVM.records.length;
    this.accountService.getUtilities(this.consumerId, this.paginationVM).subscribe(
      res => {
        this.utilities = [];
        if (res) {
          this.paginationVM = res;
          this.utilities = new Array<RegisterUtility>();
          this.utilities = res.records;
          this.spinner.hide();

          if (this.increase) {
            this.showingPage = this.paginationVM.pageNumber === 0 ? this.paginationVM.records.length : this.showingPage + this.paginationVM.records.length;
          }
          else if (this.decrease) {
            this.showingPage = this.paginationVM.pageNumber === 0 ? this.paginationVM.records.length : this.showingPage - previousPageSize;
          } else {
            this.showingPage = this.paginationVM.records.length;
          }

          this.previousPageSize = this.paginationVM.records.length;
          // tslint:disable-next-line:max-line-length
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
        }
      },
      err => {
        this.spinner.hide();
        // alert(err);
      }
    );

  }

  back() {
    this.router.navigate(['/dashboard']);
  }

  next() {
    this.router.navigate(['/dashboard']);
  }
/*Check if utility is already subscribed */
  checkUtilitySubscribed(form: NgForm, template) {
    if (form.valid) {
      this.newUtility.merchantId = this.registerUtility.merchantId;
      this.newUtility.utilityId = this.registerUtility.id;
      this.newUtility.accountNumber = this.registerUtility.accountNumber;
      this.accountService.checkSubscribedUtility(this.consumerId, this.newUtility).subscribe(
        res => {
          if (res) {
            this.subcribeUtility = new SubsribedUtilities();
            this.subcribeUtility = res;
            template.show();
          } else {
            this.registerConsumerUtility(form, template);
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  }
/*Register consumer utility*/
  registerConsumerUtility(form: NgForm, temp) {
    if (this.subcribeUtility != null) {
      this.newUtility.subcribedUtilities = this.subcribeUtility;
    }
    temp.hide();
    this.spinner.show();
    this.accountService.registerUtility(this.consumerId, this.newUtility).subscribe(
      res => {
        if (res) {
          this.paginationVM.pageNumber = 0;
          this.getUtilities();

          if (res.name === 'Registered') {
            this.accountRegisterd = true;
            this.invalidAccNo = false;
            this.toastr.warning('This account is already registered.', '', {
              timeOut: 3000
            });
          } else if (res.name === 'Activate') {
            this.toastr.success('Account activate successfully.', '', {
              timeOut: 3000
            });
            form.resetForm();
          } else if (res.name === 'Success') {
            this.toastr.success('Bill type registered successfully.', '', {
              timeOut: 3000
            });
            //this.toastr.success('Utility registered successfully.');
            form.resetForm();
          } else if (res.name === 'Failed') {
            this.invalidAccNo = true;
            this.accountRegisterd = false;
            this.toastr.error('Unable to register', '', {
              timeOut: 3000
            });
            //timeout(this.toastr['error']('Unable to register'), 2000);
            //this.toastr.error('Unable to register');
          }
          //form.resetForm();
          this.spinner.hide();
        } else {
          this.toastr.warning('Account number is invalid.', '', {
            timeOut: 3000
          });
          this.invalidAccNo = true;
          this.spinner.hide();
        }

      },
      err => {
        this.spinner.hide();
      }
    );
  
  }


  deleteUtilities() {
    if (this.selectUtility.id) {
      this.spinner.show();
      if (this.selectUtility.isActive) {
        this.selectUtility.isActive = false;
      } else {
        this.selectUtility.isActive = true;
      }
      this.accountService.DeleteUtility(this.selectUtility.id, this.selectUtility.isActive).subscribe(
        res => {
          if (res) {
            this.paginationVM.pageNumber = 0;
            this.getUtilities();
            this.spinner.hide();
            this.toastr.success('Utility deleted successfully.', '', {
              timeOut: 3000
            });
          } else {
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  }

  activateUtilities() {
    if (this.selectUtility.id) {
      this.spinner.show();
      this.accountService.DeleteUtility(this.selectUtility.id, true).subscribe(
        res => {
          if (res) {
            this.getUtilities();
            this.spinner.hide();
            this.toastr.success('Activated utility successfully.', '', {
              timeOut: 3000
            });
          } else {
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  }

  onSelect(utility: any) {
    this.selectUtility = utility;
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getUtilities();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getUtilities();
    }
  }


}
