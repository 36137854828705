export class Consumer {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	phone: string;
	profilePhoto: string;
	gender: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	password: string;
	lastLogin: Date;
	otp: string;
	isActive: boolean;
	isLocked: boolean;
	createdOn: Date;
	createdBy: string;
	updatedOn: Date;
  updatedBy: string;
  lastUpdatedPassword : Date;
  isPasswordExpired: string;
  constructor() {
    this.id = null;
    this.email = null;
    this.firstName = null;
    this.lastName = null;
    this.phone = null;
    this.profilePhoto = null;
    this.gender = null;
    this.address = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.password = null;
    this.lastLogin = null;
    this.otp = null;
    this.isActive = null;
    this.isLocked = null;
    this.createdOn = null;
    this.createdBy = null;
    this.updatedOn = null;
    this.updatedBy = null;
    this.lastUpdatedPassword = new Date;
    this.isPasswordExpired = null;
  }
}
