import { PaymentRecieptComponent } from "./../payments/payment-reciept/payment-reciept.component";
import { BillChartComponent } from "./../bills/bill-chart/bill-chart.component";
import { ChangePasswordComponent } from "app/account/change-password/change-password.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuickPayComponent } from "./quick-pay/quick-pay.component";
import { FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { RouterModule } from "@angular/router";
import { SpinnerComponent } from "./spinner.component";
import { BillDetailsComponent } from "app/bills/bill-details/bill-details.component";
import { ListBillsPaymentComponent } from "app/bills/list-bills-payment/list-bills-payment.component";
import { AddUtilityComponent } from "app/account/add-utility/add-utility.component";
import { EditProfileComponent } from "app/account/edit-profile/edit-profile.component";
import { ModalContentComponent } from "./modal-content/modal-content.component";
import { TransactionViewPipe } from "./transaction-view.pipe";

import { PaymentHistoryComponent } from "app/payments/payment-history/payment-history.component";
import { BillHistoryComponent } from "app/bills/bill-history/bill-history.component";
import { BsDatepickerModule, ModalModule, PopoverModule } from "ngx-bootstrap";
import { OnlyNumberDirectiveDirective } from "app/account/_shared/model/only-number-directive.directive";
import { TermsAndConditionsComponent } from "./terms-and-conditions/terms-and-conditions.component";
import { PrivacyPalicyComponent } from "./privacy-palicy/privacy-palicy.component";
import { PaymentReceiptModalComponent } from "./payment-receipt-modal/payment-receipt-modal.component";
import { TextMaskModule } from "angular2-text-mask";
import { SessionOutComponent } from "./session-out/session-out.component";
import { SubscribedEbillsComponent } from "app/bills/subscribed-ebills/subscribed-ebills.component";
import { NoRightClickDirective } from "./no-right-click.directive";
import { BillNewsTickerComponent } from "app/bills/bill-news-ticker/bill-news-ticker.component";
import { AutoOrRecurringPaymentComponent } from "app/payments/auto-or-recurring-payment/auto-or-recurring-payment.component";
import { UpdateCardDetailsComponent } from "app/payments/update-card-details/update-card-details.component";
import { BtxAutoRecurringComponent } from "app/payments/btx-auto-recurring/btx-auto-recurring.component";

@NgModule({
  declarations: [
    QuickPayComponent,
    AddUtilityComponent,
    SpinnerComponent,
    BillDetailsComponent,
    ListBillsPaymentComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ModalContentComponent,
    TransactionViewPipe,
    PaymentHistoryComponent,
    BillHistoryComponent,
    BillChartComponent,
    OnlyNumberDirectiveDirective,
    TermsAndConditionsComponent,
    PrivacyPalicyComponent,
    PaymentReceiptModalComponent,
    SessionOutComponent,
    SubscribedEbillsComponent,
    NoRightClickDirective,
    BillNewsTickerComponent,
    AutoOrRecurringPaymentComponent,
    UpdateCardDetailsComponent,
    BtxAutoRecurringComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    RouterModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TextMaskModule,
    PopoverModule.forRoot(),
  ],
  exports: [
    QuickPayComponent,
    AddUtilityComponent,
    SpinnerComponent,
    BillDetailsComponent,
    ListBillsPaymentComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ModalContentComponent,
    TransactionViewPipe,
    BillChartComponent,
    OnlyNumberDirectiveDirective,
    TermsAndConditionsComponent,
    PaymentReceiptModalComponent,
    SessionOutComponent,
    SubscribedEbillsComponent,
    NoRightClickDirective,
    BillNewsTickerComponent,
    AutoOrRecurringPaymentComponent,
    UpdateCardDetailsComponent,
    BtxAutoRecurringComponent,
  ],
  providers: [ModalContentComponent, PaymentReceiptModalComponent],
  bootstrap: [ModalContentComponent],
  entryComponents: [PaymentReceiptModalComponent, SessionOutComponent],
})
export class SharedModule {}
