// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   //api: 'http://localhost:62001/',
   api:'https://demo.billtrax.online/API/',
  merchantUrl: 'https://demomerchant.billtrax.online/',
  mobileAppUrl:'https://m.billtrax.online/',
  hostUrl : 'localhost:4200'

  // api: 'https://billtrax.com/API/',
  // merchantUrl: 'https://merchant.billtrax.com/',
  // mobileAppUrl:'https://m.billtrax.com/'
};
/*s
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


//Copy from NoSQLBooster for MongoDB free edition. This message does not appear if you are using a registered version.

