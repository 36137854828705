export class IntelliPayResponseVM {
  public billId: string;
  public consumerId: string;
  public utilityId: string;
  public merchantId: string;
  public accountNumber: string;
  public intelliPayResponse: string;
  public email: string;
  public paymentChannel: string;
  public paidPreviousBalance: boolean;
  public paymentMode: string;

  constructor(){
      this.billId = null;
      this.consumerId = null;
      this.merchantId = null;
      this.utilityId = null;
      this.accountNumber = null;
      this.intelliPayResponse = null;
      this.email = null;
      this.paymentChannel = null;
      this.paidPreviousBalance = false;
      this.paymentMode = null;
  }
}
