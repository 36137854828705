export class BillerContent {
    public domainName: string;
    public logoUrl: string;
    public contentDetails: Content;
    public address: Address;
    constructor(){
        this.domainName = null;
        this.logoUrl = null;
        this.contentDetails = new Content();
        this.address = new Address();
    }
}

export class Content {
    public domainPage: string;
    public htmlContent: string;
    constructor(){
        this.domainPage = null;
        this.htmlContent = null;
    }
}

export class Address {
    public addr: string;
    public city: string;
    public state: string;
    public zip: string;
    public phone: string;
    public fax: string;
    constructor(){
        this.addr = null;
        this.city = null;
        this.state = null;
        this.zip = null;
        this.phone = null;
        this.fax = null;
    }
}