import { Consumer } from "./../../account/_shared/model/consumer";
import { Receipt } from "app/_shared/model/receipt-model";
import { NgForm } from "@angular/forms";
import { CommonService } from "./../../_core/common.service";
import { ConsumerBill } from "./../../bills/_shared/model/consumer-bill";
import { PaymentsService } from "./../../payments/payments.service";
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { QuickPay } from "../model/quickpay.model";
import { NgxSpinnerService } from "ngx-spinner";
import { AccountService } from "app/account/account.service";
import { ConsumerUtility } from "../model/consumer-utility";
import { UtilityMaster } from "../model/utility-master";
import { Merchant } from "app/administration/_shared/model/merchant";
import { Utility } from "../model/utility";
import { ToastrService } from "ngx-toastr";
import { PaymentSuccess } from "../model/payment-success";
import { Constant } from "../constant";
import { BsModalService, BsModalRef } from "ngx-bootstrap";
import { Proxynization } from "../model/proxynization-model";
import { NewUtility } from "app/account/_shared/model/new-utility";
import { SubsribedUtilities } from "app/account/_shared/model/subsribed-utilities";
import { IntelliPayResponseVM } from "app/payments/_shared/model/intelli-pay-response-vm";
import { BillsService } from "app/bills/bills.service";
import { Ng2DeviceService } from "ng2-device-detector";
import { SiteVisit } from "../model/site-visit";
import { Jsonp } from "@angular/http";
import { PrivacyPalicyComponent } from "../privacy-palicy/privacy-palicy.component";
import { environment } from "environments/environment";
import { BillerContent } from "../model/biller-content";
declare var $: any;
declare var intellipay: any;

@Component({
  selector: "app-quick-pay",
  templateUrl: "./quick-pay.component.html",
})
export class QuickPayComponent implements OnInit {
  @ViewChild("year", { static: false }) input: ElementRef;
  @ViewChild("debitYear", { static: false }) debitYear: ElementRef;

  @ViewChild("payNowDashboard", { static: false }) payNowDashboard: ElementRef;
  @ViewChild("payNowDashboard1", { static: false }) payNowDashboard1: ElementRef;
  @ViewChild("payNowDashboard2", { static: false }) payNowDashboard2: ElementRef;
  bsModalRef: BsModalRef;

  urls: string;
  hideBack = true;
  quickPaylogo = true;
  quickPayModel = new QuickPay();
  isStepOneCompleted: boolean = false;
  isStepTwoCompleted: boolean = false;
  termsAndCondition: string;
  masterList: UtilityMaster[];
  isTermsAndConditionChecked: boolean;
  consumerUtilityList: ConsumerUtility[];
  merchantList: Merchant[];
  consumerBill: ConsumerBill;
  registeredUtilities: Utility[];
  bill: ConsumerBill;
  billNotFound = false;
  paymentSuccess: PaymentSuccess;
  billId: string;
  accountPattern = Constant.NUMBER_REGEXP;
  billDoesNotExist: boolean;
  today: Date;
  paymentModel: PaymentSuccess;
  billShow: boolean;
  paymentView: boolean;
  proxynization: Proxynization;
  errorMsg = "";
  selectedTab = "credit";
  receipt: Receipt;
  consumer: Consumer;
  newUtility: NewUtility;
  monthRegex = Constant.MONTH_REGEX;
  accountNumberRegex = Constant.NUMBER_REGEXP;
  cardHolderNameRegex = Constant.NAME_REGEXP;
  yearRegex = Constant.NUMBER_REGEXP;
  subcribeUtility: SubsribedUtilities;
  emailPattern = Constant.EMAIL_REGEXP;
  intellipayVM: IntelliPayResponseVM;
  deviceInfo: any;
  browserDetails: SiteVisit;
  isPreviousBalnceChecked: boolean;
  content: BillerContent;
  IsValidEditable: any;


  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private paymentsService: PaymentsService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private billService: BillsService,
    private deviceService: Ng2DeviceService,
    private _jsonp: Jsonp,
    private modalService: BsModalService
  ) {
    this.content = new BillerContent();
    this.proxynization = new Proxynization();
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);

    this.billDoesNotExist = false;
    this.merchantList = new Array<Merchant>();
    this.consumerBill = new ConsumerBill();
    this.registeredUtilities = new Array<Utility>();
    this.bill = new ConsumerBill();
    this.paymentSuccess = new PaymentSuccess();
    this.paymentModel = new PaymentSuccess();
    this.proxynization = new Proxynization();
    this.newUtility = new NewUtility();
    this.subcribeUtility = new SubsribedUtilities();
    this.paymentView = false;
    this.billShow = true;
    this.receipt = new Receipt();
    this.intellipayVM = new IntelliPayResponseVM();
    this.browserDetails = new SiteVisit();
    this.isPreviousBalnceChecked = false;

    this.consumer = this.commonService.getConsumerData();
   // this.getContent();
    this.getMerchants();
    this.GetDeviceDetails();
  }

  ngOnInit() {
    // var tallness = $('#quickpay').height();
    // $('#firstcol').height(tallness);

    // $('.height-terms-condition').each(function() {
    //   var myHeight = $(window).height() - 190;
    //   $(this).slimscroll({
    //     height: myHeight
    //   });
    // });

    if (this.router.url === "/account/quick-pay") {
      this.hideBack = false;
    }
    if (this.router.url === "/dashboard/quick-pay-dashboard") {
      this.quickPaylogo = false;
    }

  }

  changeSwitch(event: boolean) {
    this.isPreviousBalnceChecked = event;
  }

  GetDeviceDetails() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const location = position.coords;
        this.browserDetails.latitude = location.latitude.toString();
        this.browserDetails.longitude = location.longitude.toString();
      });
    }
    this._jsonp.get("//api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK").subscribe((response) => {
      const data = response.json();
      this.browserDetails.ipAddress = data.ip;
      this.browserDetails.operatingSystem = data.os;
    });
    this.browserDetails.osVersion = this.deviceInfo.os_version;
    this.browserDetails.browserVersion = this.deviceInfo.browser_version;
    this.browserDetails.browserName = this.deviceInfo.browser;
    this.browserDetails.device = this.deviceInfo.device;
  }

  /* get merchant list */
  getMerchants() {
    const host = window.location.host === environment.hostUrl ? null : window.location.host;
    console.log('host :'+ window.location.host +" hosturl :"+ environment.hostUrl)
    this.spinner.show();
    this.accountService.getMerchantsByHost(host).subscribe(
      (res) => {
        if (res) {
          this.merchantList = res;
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* view bill in new tab */
  viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, utilityName, merchantName, templateVersion) {
    this.urls = this.commonService.viewBillAnotherTab(
      billId,
      merchantId,
      utilityId,
      accountNumber,
      merchantName,
      utilityName,
      templateVersion
    );
  }

  /* get utilities os specific merchant */
  getUtilitiesFromMerchantId(merchantId: string) {
    this.spinner.show();
    this.accountService.getUtilitiesFromMerchant(merchantId).subscribe(
      (res) => {
        if (res) {
          this.registeredUtilities = res;
          this.getConfiguration(merchantId);
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  viewPaymentReceipt(paymentId, merchantId, utilityId) {
    this.urls = "/payment/payment-receipt?id=" + paymentId + "&merchantId=" + merchantId + "&utilityId=" + utilityId;
  }

  /* open privacy policy modal */
  viewPrivacyPolicy() {
    this.bsModalRef = this.modalService.show(PrivacyPalicyComponent, {
      class: "modal-lg",
    });
    this.bsModalRef.content.closeBtnName = "Discard";
  }

  /* get back to dashboard */
  backToDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  /* get back to home page */
  backToHome() {
    this.router.navigate(["/"]);
  }

  termsAndConditions(event) {
    this.isTermsAndConditionChecked = event.target.checked;
  }

  /* get specific bill utility id, merchant id, account number */
  getBill(valid: boolean) {
    this.billDoesNotExist = false;
    this.billShow = false;
    this.paymentView = false;
    if (valid) {
      this.spinner.show();
      this.accountService.getBill(this.consumerBill).subscribe(
        (res) => {
          if (res) {
            this.bill = res;
            this.isStepOneCompleted = true;
            this.billShow = true;
            this.isPreviousBalnceChecked = false;
            this.proxynization.email = this.consumer !== null ? this.consumer.email : "";
            if (this.bill.billType === "intellipay") {
              this.callIntellipay(this.bill.merchantId, this.bill.utilityId);
            }
            this.spinner.hide();
          } else {
            this.billDoesNotExist = true;
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
          this.billNotFound = true;
          this.toastr.warning("No bill available for " + this.consumerBill.accountNumber + " account number.", "", {
            timeOut: 3000,
          });
        }
      );
    }
  }

  /* check if account number is already registered with other consumer */
  checkUtilitySubscribed(bill: ConsumerBill, template) {
    this.newUtility.merchantId = bill.merchantId;
    this.newUtility.utilityId = bill.utilityId;
    this.newUtility.accountNumber = bill.accountNumber;
    this.accountService.checkSubscribedUtility(this.consumer.id, this.newUtility).subscribe(
      (res) => {
        if (res) {
          this.subcribeUtility = new SubsribedUtilities();
          this.subcribeUtility = res;
          template.show();
        } else {
          this.goToDashboard(template);
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* register utility */
  goToDashboard(template) {
    if (this.subcribeUtility != null) {
      this.newUtility.subcribedUtilities = this.subcribeUtility;
    }
    this.spinner.show();
    template.hide();
    this.accountService.registerUtility(this.consumer.id, this.newUtility).subscribe(
      (res) => {
        if (res) {
          if (res.name === "Registered") {
            this.toastr.warning("This account is already registered.", "", {
              timeOut: 3000,
            });
          } else if (res.name === "Activate") {
            this.toastr.success("Account activate successfully.", "", {
              timeOut: 3000,
            });
          } else if (res.name === "Success") {
            this.toastr.success("Bill type registered successfully.", "", {
              timeOut: 3000,
            });
          } else if (res.name === "Failed") {
            this.toastr.error("Unable to register", "", {
              timeOut: 3000,
            });
          }
          this.spinner.hide();
        } else {
          this.toastr.warning("Account number is invalid.", "", {
            timeOut: 3000,
          });
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* return to login */
  goToLogin() {
    this.router.navigate(["account/login"]);
  }

  goToFirst() {
    //not go from 3rd to 1st and 2nd
    if (this.isStepOneCompleted && !this.isStepTwoCompleted)
      //go from 2nd to 1st
      this.isStepOneCompleted = false;
    this.billShow = false;
  }

  /* increment site visit count */
  siteVisit(bill: ConsumerBill) {
    // this.spinner.show();
    this.commonService.siteVisit(bill.merchantId, bill.utilityId, bill.accountNumber).subscribe(
      (res) => {
        if (res) {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* increment site visit count */
  siteVisitBeforelogin(bill: ConsumerBill, email: string) {
    // this.spinner.show();
    this.commonService.siteVisitBeforelogin(bill.merchantId, bill.utilityId, bill.accountNumber, this.browserDetails, email).subscribe(
      (res) => {
        if (res) {
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  /* view payment gateway screen */
  viewPaymentPopUp(form: NgForm) {
    if (form.valid && this.isTermsAndConditionChecked) {
      this.billShow = false;
      this.paymentView = true;
    }
  }

  /* view pdf from cloud */
  viewPdfFromCloud(pdfName: string, containerName: string, merchantId: string, utilityId: string, accountNumber: string) {
    this.urls = this.commonService.viewPdfFromCloud(pdfName, containerName);
  }

  callIntellipay(merchantId, utilityId) {
    this.spinner.show();
    this.billService.intelliPayIn(merchantId, utilityId).subscribe((res) => {
      if (res) {
        const rg = document.createRange();
        document.documentElement.appendChild(rg.createContextualFragment(res.result));
        intellipay.setItemLabel("account", "Account Number");
        intellipay.setItemLabel("button", "Pay Now"); //Set the pay button text
        if (this.IsValidEditable) {
          intellipay.enable('amount');
        }
        intellipay.disable("email");
        intellipay.initialize();
        this.spinner.hide();
        const _this = this;
        intellipay.runOnApproval(function (response) {
          _this.storeIntelliPayResponse(JSON.stringify(response));
        });
      }
    });
  }

  storeIntelliPayResponse(response) {
    this.intellipayVM.intelliPayResponse = response;
    this.intellipayVM.email = this.proxynization.email;
    this.billService.storeIntelliPayResponse(this.intellipayVM).subscribe((res) => {
      if (res) {
        this.receipt = res;
        if (this.receipt.paymentStatus === "success") {
          this.isStepTwoCompleted = true;
        }
        if (this.receipt.paymentStatus === "failed") {
          this.toastr.error("Transaction failed", "", {
            timeOut: 3000,
          });
        }
        if (this.router.url === "/dashboard/quick-pay-dashboard") {
          this.siteVisit(this.bill);
        } else {
          this.siteVisitBeforelogin(this.bill, this.proxynization.email);
        }
      }
    });
  }

  pay(bill: ConsumerBill, form: NgForm, isPreviousBalance) {
    if (form.valid && this.isTermsAndConditionChecked && bill.totalDueAmount > 0) {
      this.spinner.show();
      this.intellipayVM = {
        consumerId: this.consumer != null ? this.consumer.id : null,
        billId: bill.id,
        merchantId: bill.merchantId,
        utilityId: bill.utilityId,
        accountNumber: bill.accountNumber,
        intelliPayResponse: null,
        email: this.consumer != null ? this.consumer.email : null,
        paymentChannel: "BillTrax",
        paidPreviousBalance: isPreviousBalance,
        paymentMode: "WEB",
      };
      // this.payNowDashboard1.nativeElement.attributes[5].nodeValue = bill.totalDueAmount;
      if (bill.allowPartialPayment === 'yes') {
            if (bill.totalDueAmount > 0) {
              this.payNowDashboard1.nativeElement.attributes[5].nodeValue = isPreviousBalance ? bill.previousBalance : bill.totalDueAmount;
            } else {
              this.payNowDashboard1.nativeElement.attributes[5].nodeValue = -1.00;
            }
      } else {
            if (bill.totalDueAmount > 0) {
              this.payNowDashboard1.nativeElement.attributes[5].nodeValue = isPreviousBalance ? bill.previousBalance : bill.totalDueAmount;
            } else {
              this.payNowDashboard1.nativeElement.attributes[5].nodeValue = -1.00;
            }
        }
      //  this.payNowDashboard1.nativeElement.attributes[5].nodeValue = -1.00;
      // } else {
      //  this.payNowDashboard1.nativeElement.attributes[5].nodeValue = isPreviousBalance ? bill.previousBalance : bill.totalDueAmount;
      // }
      this.payNowDashboard2.nativeElement.attributes[5].nodeValue = bill.accountNumber;
      this.payNowDashboard.nativeElement.click();
      this.spinner.hide();
    }
  }

  getContent() {
    const data = {
      DomainName: window.location.host, //window.location.host,
      DomainPage: this.router.url //this.router.url
    }
    this.accountService.getBillerContent(data).subscribe(
      (res) => {
        if (res) {
          this.content = res;
        }
      },
      (err) => {
      }
    );
  }

  //7 aug 2023
  getConfiguration(merchantId) {
    this.spinner.show();
    this.billService.getConfiguration(merchantId).subscribe(
      (res) => {
        if (res) {
          this.IsValidEditable = res.IsValidEditable;
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
}
