import { Injectable } from "@angular/core";
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { ConsumerNotifications } from 'app/notification/_shared/model/consumer-notifications';
import { NotificationFilter } from './_shared/model/notification-filter';
import { DataService } from 'app/_core/data.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { ConsumerNotificationResult } from './_shared/model/consumer-notification-result';

@Injectable({
  providedIn: "root"
})
export class NotificationService {
  private updateNotifications = new BehaviorSubject(false);
  notificationUpdate = this.updateNotifications.asObservable();
  
  constructor(private dataService: DataService) {}

  GetNotificatins(consumerId: string): Observable<ConsumerNotifications[]> {
		return this.dataService.get<ConsumerNotifications[]>('notifications/getnotifications/' + consumerId);
	}

  GetNotifications(consumerId: string, billSearchResult: PaginationVM<ConsumerNotificationResult, NotificationFilter>): Observable<PaginationVM<ConsumerNotificationResult, NotificationFilter>> {
    return this.dataService.post<PaginationVM<ConsumerNotificationResult, NotificationFilter>, PaginationVM<ConsumerNotificationResult, NotificationFilter>>(
      `notifications/${consumerId}`,
      billSearchResult
    );
  }

  UpdateNotification(notificationId: string): Observable<any> {
		return this.dataService.put(`notifications/${notificationId}`);
  }

  updateNotificationList(isReload: boolean) {
		this.updateNotifications.next(isReload);
	}
}
