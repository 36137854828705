import { PaymentDate } from './payment-date';

export class PaymentSuccess {
  id:string;
  status: string;
  statusCode: string;
  statusMessage: string;
  transactionId: string;
  originatorTransactionId: string;
  serviceTransactionId: string;
  serviceTransactionDateTime: string;
  captureState: string;
  transactionState: string;
  amount: string;
  cardType: string;
  feeAmount: string;
  approvalCode: string;
  batchId: string;
  cVResult: string;
  cardLevel: string;
  downgradeCode: string;
  maskedPAN: string;
  paymentAccountDataToken: string;
  retrievalReferenceNumber: string;
  orderId: string;
  currencyCode: string;
  cardProduct: string;
  expire: string;
  invoiceNumber: string;
  description: string;
  billId: string;
  accountNumber: string;
  merchantId: string;
  consumerId: string;
  utilityId: string;
  paymentDate:PaymentDate;

  constructor() {
    this.id = null;
    this.status = null;
    this.statusCode = null;
    this.statusMessage = null;
    this.transactionId = null;
    this.originatorTransactionId = null;
    this.serviceTransactionId = null;
    this.serviceTransactionDateTime = null;
    this.captureState = null;
    this.transactionState = null;
    this.amount = null;
    this.cardType = null;
    this.feeAmount = null;
    this.approvalCode = null;
    this.batchId = null;
    this.cVResult = null;
    this.cardLevel = null;
    this.downgradeCode = null;
    this.maskedPAN = null;
    this.paymentAccountDataToken = null;
    this.retrievalReferenceNumber = null;
    this.orderId = null;
    this.currencyCode = null;
    this.cardProduct = null;
    this.expire = null;
    this.invoiceNumber = null;
    this.description = null;
    this.billId = null;
    this.accountNumber = null;
    this.merchantId = null;
    this.consumerId = null;
    this.utilityId = null;
    this.paymentDate = new PaymentDate();
  }
}
