import { Component, OnInit } from "@angular/core";
import { AccountService } from "app/account/account.service";
import { User } from "app/_shared/model/user.model";
import { NgForm } from "@angular/forms";
import { Login } from "app/_shared/model/login.model";
import { MaxLengthConstants, Constant } from "app/_shared/constant";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { BillerContent } from "app/_shared/model/biller-content";
import { filter } from "rxjs/operators";
declare var $: any;
@Component({
  selector: "app-empty",
  templateUrl: "./account.layout.component.html"
})
export class AccountLayoutComponent implements OnInit {
  content: BillerContent;
  constructor(private accountService: AccountService, private router: Router) {
    this.content = new BillerContent();

    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))  
    .subscribe((event: NavigationEnd) => {
      if (event.url === '/account/login' || event.url === '/account/register' || event.url === '/account/forgot-password'
      || event.url === '/account/quick-pay') {
        this.getContent(window.location.host, event.url);
      }
    });
  }

  ngOnInit() {
    $(".full-height").height($(window).height());
  }

  getContent(domainName: string, domainPage: string) {
    const data = {
      DomainName: domainName, //window.location.host,
      DomainPage: domainPage //this.router.url
    }
    this.accountService.getBillerContent(data).subscribe(
      (res) => {
        if (res) {
          this.content = res;
        }
      },
      (err) => {
      }
    );
  }
}
