import { ItemId } from "app/bills/_shared/model/item-id";

export class ScheduledPayments {
  public id: string;
  public consumer: ItemId;
  public utility: ItemId;
  public merchant: ItemId;
  public accountNumber: string;
  public paymentType: string;
  public scheduleType: string;
  public recurringAmount: string;
  public paymentSchedule: PaymentSchedule;
  public transaction: Transaction;
  public createdBy: string;
  public createdOn: Date;
  public modifiedBy: string;
  public modifiedOn: Date;
  public isActive: boolean;
  public isLocked: boolean;

  constructor() {
    this.id = null;
    this.consumer = new ItemId();
    this.utility = new ItemId();
    this.merchant = new ItemId();
    this.accountNumber = null;
    this.paymentType = null;
    this.scheduleType = null;
    this.recurringAmount = null;
    this.paymentSchedule = new PaymentSchedule();
    this.transaction = new Transaction();
    this.createdBy = null;
    this.createdOn = null;
    this.modifiedBy = null;
    this.modifiedOn = null;
    this.isActive = false;
    this.isLocked = false;
  }
}

export class Transaction {
  public customerId: string;
  public status: string;
  public paymentType: string;
  constructor(){
    this.customerId = null;
    this.status = null;
    this.paymentType = null;
  }
}

export class PaymentSchedule {
  public day: string;
  public description: string;
  constructor (){
    this.day = null;
    this.description = null;
  }
}
