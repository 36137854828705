import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { DataService } from "app/_core/data.service";
import { BillViewModel } from "./_shared/model/bill-view-model";
import { BillSearchResult } from "./_shared/model/bill-search-result";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { BillFilter } from "./_shared/model/bill-filter";
import { Utility } from "app/_shared/model/utility";
import { LatestBill } from "./_shared/model/latest-bill";
import { PaymentSearchResult } from "app/payments/_shared/model/payment-search-result";
import { OptInEBills } from "./_shared/model/opt-in-e-bills";
import { IntelliPayResponseVM } from "app/payments/_shared/model/intelli-pay-response-vm";
import { Receipt } from 'app/_shared/model/receipt-model';
import { ExistAccounts } from 'app/payments/_shared/model/exist-accounts';

@Injectable({
  providedIn: "root"
})
export class BillsService {
  private updateBillList = new BehaviorSubject(false);
  billsReload = this.updateBillList.asObservable();


  private reloadDashboardBillsAndPayment = new BehaviorSubject(false);
  reloadDashboard = this.reloadDashboardBillsAndPayment.asObservable();


  constructor(private dataService: DataService) {}

  ExportToPDF(billId: string, merchantId: string, utilityId: string, accountNumber: string): Observable<any> {
    return this.dataService.get(`bills/pdf/${utilityId}/${merchantId}/${billId}/${accountNumber}`);
  }

  getbillhistorylist(): Observable<any> {
    return this.dataService.get("bill");
  }

  getBillHistoryByUserId(
    consumerId: string,
    billSearchResult: PaginationVM<BillSearchResult, BillFilter>
  ): Observable<PaginationVM<BillSearchResult, BillFilter>> {
    return this.dataService.post<PaginationVM<BillSearchResult, BillFilter>, PaginationVM<BillSearchResult, BillFilter>>(
      `bills/${consumerId}`,
      billSearchResult
    );
  }

  getPaymentHistoryByUserId(
    consumerId: string,
    paymentSearchResult: PaginationVM<PaymentSearchResult, BillFilter>
  ): Observable<PaginationVM<PaymentSearchResult, BillFilter>> {
    return this.dataService.post<PaginationVM<PaymentSearchResult, BillFilter>, PaginationVM<PaymentSearchResult, BillFilter>>(
      `payments/${consumerId}`,
      paymentSearchResult
    );
  }

  GetBill(billId: string, merchantId: string, utilityId: string, accountNumber: string): Observable<BillViewModel> {
    return this.dataService.get(`bills/bill/${utilityId}/${merchantId}/${billId}/${accountNumber}`);
  }

  getDataFromObject(model) {
    const params = new URLSearchParams();
    for (const key in model) {
      params.set(key, model[key]);
    }
    params.toString();
    return params;
  }

  getUtilitiesFromConsumer(consumerId: string): Observable<Utility[]> {
    return this.dataService.get<Utility[]>(`utilities/${consumerId}`);
  }

  getBillDetails(consumerId: string): Observable<LatestBill[]> {
    return this.dataService.get(`consumerDashboard/${consumerId}/bills`);
  }

  GetBillDetails(consumerId: string, merchantId: string): Observable<any> {
    return this.dataService.get(`bills/merchant/${merchantId}/${consumerId}`);
  }
  utilityBillDetails(merchantId: string, accountNumber: string, utilityId: string): Observable<any> {
    return this.dataService.get(`bills/latestbill/${merchantId}/${accountNumber}/${utilityId}`);
  }

  getPDFView(utilityId: string, merchantId: string, accountNumber: string): Observable<any> {
    return this.dataService.get(`bills/bill/${utilityId}/${merchantId}/${accountNumber}`);
  }

  updateBillDetailsList(load: boolean) {
    this.updateBillList.next(load);
  }

  PayUsingACH(optIn: OptInEBills): Observable<OptInEBills> {
    return this.dataService.post("payments/ChargeTheCard", optIn);
  }

  OptInForEbills(eBills: OptInEBills, merchantId: string): Observable<any> {
    const methodName = `consumerDashboard/optine-bills?merchantId=${merchantId}`;
    return this.dataService.post(methodName, eBills);
  }

  DeActivateOptInForEbills(eBills: OptInEBills, merchantId: string): Observable<any> {
    const methodName = `consumerDashboard/deActive-e-Bills?merchantId=${merchantId}`;
    return this.dataService.post(methodName, eBills);
  }

  viewPdfFromCloud(pdfName: string, containerName: string): Observable<any> {
    return this.dataService.get(`bills/getpdffromcloud/${pdfName}/${containerName}`);
  }

  // intelliPay(merchantId: string): Observable<any> {
  //   return this.dataService.get(`payments/intelliPay/${merchantId}`);
  // }

  intelliPay(): Observable<any> {
    return this.dataService.get('payments/intelliPay/');
  }

  lightboxEMV(): Observable<any> {
    return this.dataService.get('payments/lightboxEMV/');
  }

  storeIntelliPayResponse(response: IntelliPayResponseVM): Observable<Receipt> {
    return this.dataService.post("payments/storeintellipayresponse", response);
  }

  reloadPaymentAndBillsDashboard(isReload: boolean) {
    this.reloadDashboardBillsAndPayment.next(isReload);
  }

  intelliPayIn(merchantId, utilityId): Observable<any> {
    return this.dataService.get(`payments/intelliPay/${merchantId}/${utilityId}`);
  }

  intelliPayUniqueId(merchantId, utilityId): Observable<any> {
    return this.dataService.get(`payments/intelliPayUnique/${merchantId}/${utilityId}`);
  }

  checkExistAccounts(existAccounts: ExistAccounts): Observable<any> {
    return this.dataService.post("btxbiller/getexistaccounts", existAccounts);
  }

  //4 july 2023
  getOptInData(ebillId: string, merchantId: string): Observable<any> {
    return this.dataService.get(`merchant/Reports/ebill-data/${ebillId}/${merchantId}`);
  }

  isWantedPrintedBill(data: any): Observable<any> {
    return this.dataService.post('merchant/Reports/UpdateOptinEbill', data);
  }

  // 7 Aug 2023
  getConfiguration(merchantId: string): Observable<any> {
    return this.dataService.get(`merchants/getConfiguration/${merchantId}`);
  }
}
