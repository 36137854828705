export class ContactUs {
    name: string;
    email: string;
    phone: string;
    query: string;
    constructor()
    {
        this.name= null;
        this.email= null;
        this.phone = null;
        this.query = null;
    }
}
