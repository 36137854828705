import { PaymentLayoutComponent } from "./layouts/payment-layout.component";
import { EmptyLayoutComponent } from "./layouts/empty-layout.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StaticComponent } from "./layouts/static.component";
import { MainComponent } from "./layouts/main.component";
import { AccountLayoutComponent } from "./layouts/account.layout.component";
import { AdminLayoutComponent } from "./layouts/admin-layout.component";
const routes: Routes = [
  {
    path: "",
    component: StaticComponent,
    data: { title: "Static Page" }
  },
  {
    path: "",
    component: AccountLayoutComponent,
    data: { title: "Main Page" },
    children: [
      {
        path: "account",
        loadChildren: "./account/account.module#AccountModule"
      }
     
    ]
  },
  {
    path: "",
    component: MainComponent,
    data: { title: "Main Page" },
    children: [
      {
        path: "dashboard",
        loadChildren: "./dashboard/dashboard.module#DashboardModule"
      },

      {
        path: "payments",
        loadChildren: "./payments/payments.module#PaymentsModule"
      },
      {
        path: "bills",
        loadChildren: "./bills/bills.module#BillsModule"
      },
      {
        path: "common-page",
        loadChildren: "./common-page/common-page.module#CommonPageModule"
      },
      {
        path: "setting",
        loadChildren: "./setting/setting.module#SettingModule"
      },
      {
        path: "notification",
        loadChildren: "./notification/notification.module#NotificationModule"
      }
    ]
  },
  {
    path: "",
    component: AdminLayoutComponent,
    data: { title: "Admin Page" },
    children: [
      {
        path: "admin",
        loadChildren: "./administration/administration.module#AdministrationModule"
      }
    ]
  },

  {
    path: "",
    component: EmptyLayoutComponent,
    data: { title: "" },
    children: [
      {
        path: "bill",
        loadChildren: "./bills/bills.module#BillsModule"
      },
      {
        path: "payment",
        loadChildren: "./payments/payments.module#PaymentsModule"
      },
      {
        path: "dashboards",
        loadChildren: "./dashboard/dashboard.module#DashboardModule"
      },
      {
        path: "schedule",
        loadChildren: "./payments/payments.module#PaymentsModule"
      },
    ]
  },

  {
    path: "",
    component: PaymentLayoutComponent,
    data: { title: "" },
    children: [
      {
        path: "payment-wizard",
        loadChildren: "./popup-wizard/popup-wizard.module#PopupWizardModule"
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
