import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BillSearchResult } from "../_shared/model/bill-search-result";
import { BillsService } from "../bills.service";
import { LatestBill } from "../_shared/model/latest-bill";
import { CommonService } from "app/_core/common.service";
import { Payment } from "app/_shared/model/payment";
import { BillViewModel } from "../_shared/model/bill-view-model";
import { Bill } from "../_shared/model/bill";
import { Consumer } from "app/account/_shared/model/consumer";
import { PaginationVM } from "app/_shared/model/pagination.model";
import { BillFilter } from "../_shared/model/bill-filter";
import { NgxSpinnerService } from "ngx-spinner";
import { PaymentSearchResult } from "app/payments/_shared/model/payment-search-result";
import { PaymentsService } from "app/payments/payments.service";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: "app-list-bills-payment",
  templateUrl: "./list-bills-payment.component.html"
})
export class ListBillsPaymentComponent implements OnInit {
  hideBack = true;
  userBillHistoryList: BillSearchResult[];
  consumerId: string;
  latestBills: LatestBill[];
  pageNumber: number;
  latestPayments: Payment[];
  paginationVM = new PaginationVM<BillSearchResult, BillFilter>();
  paginationPaymentVM = new PaginationVM<PaymentSearchResult, BillFilter>();
  payment: Payment;
  bill: Bill;
  urls: string;
  billViewModel: BillViewModel;
  consumer: Consumer;
  billFilter: BillFilter;
  userPaymentHistoryList: PaymentSearchResult[];

  constructor(
    private router: Router,
    private billsService: BillsService,
    private billService: BillsService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentsService,
    private toastr: ToastrService
  ) {
    this.pageNumber = 1;
    this.paginationVM = <PaginationVM<BillSearchResult, BillFilter>>{
      pageNumber: 0,
      pageSize: 5,
      totalCount: 0,
      sortBy: "Id",
      sortType: "desc",
      records: this.userBillHistoryList,
      filters: this.billFilter
    };
    this.latestBills = [];
    this.consumerId = this.commonService.getConsumerUserId();
    this.billViewModel = new BillViewModel();
    this.bill = new Bill();
  }

  ngOnInit() {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });

    this.getBill();
    if (this.router.url === "/dashboard/list-bills-payment") {
      this.hideBack = false;
    }

    /// call service when payment
    this.billService.reloadDashboard.subscribe(isReload => {
      if (isReload) {
        this.getBill();
        this.getPayment();
        this.billService.reloadPaymentAndBillsDashboard(false);
      }
    });
  }

  /* get consumers bills list */
  getBill() {
    this.billsService.getBillHistoryByUserId(this.consumerId, this.paginationVM).subscribe(
      res => {
        if (res) {
          this.getPayment();
          this.paginationVM = res;
          this.userBillHistoryList = this.paginationVM.records;
          this.userBillHistoryList.forEach(element => {
            element.dueAmount = element.dueAmount.toString().includes(".") === false ? element.dueAmount + ".00" : element.dueAmount;
          });
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* get consumers payment list */
  getPayment() {
    this.billsService.getPaymentHistoryByUserId(this.consumerId, this.paginationPaymentVM).subscribe(
      res => {
        this.userPaymentHistoryList = [];
        if (res) {
          this.paginationPaymentVM = res;
          this.userPaymentHistoryList = this.paginationPaymentVM.records;
          this.userPaymentHistoryList.forEach(element => {
            element.dueAmount = element.dueAmount.toString().includes(".") === false ? element.dueAmount + ".00" : element.dueAmount;
          });
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* redirect to bill history component */
  viewBill() {
    this.router.navigate(["/bills/bill-history"]);
  }

  /* redirect to payment history component */
  viewPayment() {
    this.router.navigate(["/payments/payment-history"]);
  }

  /* export to pdf */
  exportToPDF(id: string, merchantId: string, utilityId: string, accountNumber: string) {
    if(id === ''){
      this.toastr.error('Payment Receipt Not Available');
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    this.paymentService.ExportToPDF(id, merchantId, utilityId).subscribe(
      res => {
        if (res.result != '') {
          this.downloadFile(res.result, accountNumber, "application/pdf", "download");
          this.spinner.hide();
        } else {
          this.toastr.error('Payment Receipt Not Available');
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* download pdf */
  downloadFile(data: string, accountNumber: string, fileType: any, type: string) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const downloadPDF = document.createElement("a");
    downloadPDF.href = url;
    const today = new Date();
    const date = this.commonService.formatDate(today);
    downloadPDF.download = accountNumber + "_" + date + ".pdf";
    downloadPDF.click();
    this.spinner.hide();
  }

  /* view specific bill in new tab*/
  viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, utilityName, merchantName, tempVersion) {
    this.urls = this.commonService.viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, merchantName, utilityName, tempVersion);
  }

  /* view payment receipt in new tab*/
  viewPaymentReceipt(paymentId: string, merchantId: string, utilityId: string) {
    const receipt = "/payment/payment-receipt?id=" + paymentId + "&merchantId=" + merchantId + "&utilityId=" + utilityId;
    this.urls = receipt;
  }

  /* get and view in another tab */
  viewPdfFromCloud(pdfName: string, containerName: string) {
    this.urls = this.commonService.viewPdfFromCloud(pdfName, containerName);
  }
}
