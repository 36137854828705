import { Component, OnInit } from '@angular/core';
import { CommonService } from 'app/_core/common.service';
import { Router } from '@angular/router';
import { Consumer } from 'app/account/_shared/model/consumer';
import { BillsService } from '../bills.service';
import { BillFilter } from '../_shared/model/bill-filter';
import { BillSearchResult } from '../_shared/model/bill-search-result';
import { PaginationVM } from 'app/_shared/model/pagination.model';
import { Utility } from 'app/_shared/model/utility';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountService } from 'app/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { PaymentsService } from 'app/payments/payments.service';
declare var $: any;

@Component({
  selector: 'app-payment-history',
  templateUrl: './bill-history.component.html'
})
export class BillHistoryComponent implements OnInit {
  consumer: Consumer;
  hideSearchBox = false;
  userBillHistoryList: BillSearchResult[];
  billFilter: BillFilter;
  paginationVM = new PaginationVM<BillSearchResult, BillFilter>();
  registeredUtilities: Utility[];
  pageNumber: number;
  urls: string;
  today = new Date();
  fToday = new Date();
  toDate: Date;
  minDate: Date;
  recordsCount: number;
  showingPage = 0;
  increase: boolean;
  decrease: boolean;
  oldPageLength = 0;
  lastSixMonth: Date;

  constructor(
    private billsService: BillsService,
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private paymentService: PaymentsService,
    private toastr : ToastrService
  ) {
    this.billFilter = new BillFilter();
    this.userBillHistoryList = [];
    this.pageNumber = 1;
    this.paginationVM = <PaginationVM<BillSearchResult, BillFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: 'Id',
      sortType: 'desc',
      records: this.userBillHistoryList,
      filters: this.billFilter
    };

    this.registeredUtilities = new Array<Utility>();
    this.consumer = this.commonService.getConsumerData();
    this.getRegisteredUtilities(this.consumer.id);
    this.hideSearchBox = false;
    this.recordsCount = 0;
    this.toDate = this.minDate;
    var date = new Date(this.today.getTime() - 180 * 24 * 60 * 60 * 1000);
    this.lastSixMonth = new Date(date.getFullYear(), this.today.getMonth() - 6 + 1, 1, 0, 0, 0, 0);
  }

  ngOnInit() {
    $(function() {
      $('[data-toggle="tooltip"]').tooltip();
    });

    /* load bills*/
    this.getBill();

    /* checking url in route path*/
    if (this.router.url === '/dashboard') {
      this.hideSearchBox = true;
    }
  }

  /* open view bill in another tab*/
  viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, merchantName, utilityName, tempVersion) {
    this.urls = this.commonService.viewBillAnotherTab(billId, merchantId, utilityId, accountNumber, merchantName, utilityName, tempVersion);
  }

  /* view pdf from azure blob container*/
  viewPdfFromCloud(pdfName: string, containerName: string) {
    this.urls = this.commonService.viewPdfFromCloud(pdfName, containerName);
  }

  /* get all the bills for specific consumer*/
  getBill() {
    this.spinner.show();
    this.billsService.getBillHistoryByUserId(this.consumer.id, this.paginationVM).subscribe(
      res => {
        if (res) {
          this.paginationVM = res;
          this.userBillHistoryList = new Array<BillSearchResult>();
          this.userBillHistoryList = this.paginationVM.records;
          // this.userBillHistoryList.forEach(element => {
          //   element.dueAmount = element.dueAmount.toString().includes('.') === false ? element.dueAmount + '.00' : element.dueAmount;
          // });
          if (this.increase) {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          } else if (this.decrease) {
            this.showingPage = this.showingPage - this.oldPageLength;
          } else {
            this.showingPage = this.showingPage + this.paginationVM.records.length;
          }
          this.oldPageLength = this.paginationVM.records.length;
          this.pageNumber = res.pageNumber === undefined ? 1 : res.pageNumber + 1;
          this.paginationVM.pageNumber = this.pageNumber === 1 ? 0 : this.pageNumber - 1;
          this.increase = false;
          this.decrease = false;
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* get registered utilities for specific consumer */
  getRegisteredUtilities(consumerId: string) {
    // this.spinner.show();
    this.billsService.getUtilitiesFromConsumer(consumerId).subscribe(
      res => {
        if (res) {
          this.registeredUtilities = res;
          // this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* searching data in bills*/
  searchBillHistory() {
    this.showingPage = 0;
    this.paginationVM = <PaginationVM<BillSearchResult, BillFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: 'Id',
      sortType: 'desc',
      records: this.userBillHistoryList,
      filters: this.billFilter
    };
    this.paginationVM.filters = this.billFilter;
    if(this.billFilter.from != null) {
      // tslint:disable-next-line: max-line-length
      this.billFilter.from = this.commonService.currentDateFormat(this.billFilter.from.getFullYear(),this.billFilter.from.getMonth(),this.billFilter.from.getDate(),0,0,0,0);
  }
    if(this.billFilter.to != null) {
      // tslint:disable-next-line: max-line-length
      this.billFilter.to = this.commonService.currentDateFormat(this.billFilter.to.getFullYear(),this.billFilter.to.getMonth(),this.billFilter.to.getDate(),23,59,0,0);
      }
    this.getBill();
  }

  increasePageNumber() {
    if (this.paginationVM.pageNumber < this.paginationVM.totalPage) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber + 1;
      this.increase = true;
      this.getBill();
    }
  }

  decreasePageNumber() {
    if (this.paginationVM.pageNumber >= 1) {
      this.paginationVM.pageNumber = this.paginationVM.pageNumber - 1;
      this.decrease = true;
      this.getBill();
    }
  }

  /* bind and download pdf */
  exportToPDF(id: string, merchantId: string, utilityId: string, accountNumber: string) {
    if(id === ''){
      this.toastr.error('Payment Receipt Not Available');
      this.spinner.hide();
      return;
    }
    this.spinner.show();
    this.paymentService.ExportToPDF(id, merchantId, utilityId).subscribe(
      res => {
        if (res.result != '') {
          this.downloadFile(res.result, accountNumber, 'application/pdf');
          this.spinner.hide();
        } else {
          this.toastr.error('Payment Receipt Not Available');
          this.spinner.hide();
        }
      },
      err => {
        this.spinner.hide();
      }
    );
  }

  /* download pdf*/
  downloadFile(data: string, accountNumber: string, fileType: any) {
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    const downloadPDF = document.createElement('a');
    downloadPDF.href = url;
    const today = new Date();
    const date = this.commonService.formatDate(today);
    downloadPDF.download = accountNumber + '_' + date + '.pdf';
    downloadPDF.click();
    this.spinner.hide();
  }

  onValueChange(fromDate) {
    if (fromDate) {
      this.fToday = fromDate;
    } else {
      this.fToday = this.today;
    }
  }

  /* reset search parameters for bill history*/
  reset(form: NgForm) {
    this.showingPage = 0;
    form.resetForm();
    this.paginationVM = <PaginationVM<BillSearchResult, BillFilter>>{
      pageNumber: 0,
      pageSize: 10,
      totalCount: 0,
      sortBy: 'Id',
      sortType: 'desc',
      records: this.userBillHistoryList,
      filters: this.billFilter
    };
    this.paginationVM.filters = this.billFilter;
    this.getBill();
  }
}
