import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { BillsService } from 'app/bills/bills.service';
import { CommonService } from 'app/_core/common.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PaymentsService } from '../payments.service';
import { ExistAccounts } from '../_shared/model/exist-accounts';
import { ScheduledPayments } from '../_shared/model/scheduled-payments';
declare var intellipay: any;

@Component({
  selector: 'app-btx-auto-recurring',
  templateUrl: './btx-auto-recurring.component.html',
  styleUrls: ['./btx-auto-recurring.component.css']
})
export class BtxAutoRecurringComponent implements OnInit {
  @ViewChild('payments1', { static: false }) payments1: ElementRef;
  @ViewChild('payments2', { static: false }) payments2: ElementRef;
  @ViewChild('payments3', { static: false }) payments3: ElementRef;
  @ViewChild('payments4', { static: false }) payments4: ElementRef;
  @ViewChild('payments5', { static: false }) payments5: ElementRef;
  @ViewChild('payNowDashboard', { static: false }) payNowDashboard: ElementRef;

  billerId: string;
  utilityId: string;
  accountNumber: string;
  backURL: string;
  schedulePay: ScheduledPayments;
  registeredAccounts: string[];
  accounts: string;
  showlink: boolean = false;
  openHostedLink: string;
  notExist: boolean = false;
  

  constructor(
    private commonService: CommonService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router,
    private billService: BillsService,
    private paymentsService: PaymentsService
  ) {
    this.schedulePay = new ScheduledPayments();
    this.registeredAccounts = new Array<string>();
    this.accounts = null;
  }

  ngOnInit() {
     const encryptedURL = this.router.url.split('?');
     var newStr = encryptedURL[1].charAt(encryptedURL[1].length - 1) === '=' ? encryptedURL[1].substring(0, encryptedURL[1].length - 1) : encryptedURL[1];
     const url = this.decrypt(newStr);
     const finalURL = encryptedURL[0] + '?' + url;
     this.billerId = getQueryParams('billerId', finalURL);
     this.utilityId = getQueryParams('billtypeId', finalURL);
     this.accountNumber = getQueryParams('accountnumber', finalURL);
     this.backURL = getQueryParams('backurl', finalURL);
     this.getExistAccounts();
  }

  getExistAccounts(){
    this.spinner.show();
    const existAccounts = {
      billerId : this.billerId,
      billTypeId : this.utilityId,
      accountNumber: this.accountNumber
    }
    this.billService.checkExistAccounts(existAccounts).subscribe((res) => {
      if (res) {
        if (res.result === 'exist') {
          this.loadIntellipay();
        } else {
          this.notExist = true;
          this.spinner.hide();
        }
      }
      (err) => {
        this.spinner.hide();
      }
    });
  }

  loadIntellipay(){
    this.spinner.show();
    this.billService.intelliPayUniqueId(this.billerId, this.utilityId).subscribe((res) => {
      if (res) {
        const rg = document.createRange();
        document.documentElement.appendChild(
          rg.createContextualFragment(res.result)
        );
        intellipay.setItemLabel('account', 'Account Number');
        intellipay.disable('email');
        intellipay.initialize();
        const _this = this;
        intellipay.runOnApproval(function (response) {
          _this.storeScheduledPayments(JSON.stringify(response));
        });

        setTimeout(function () {
          _this.pay();
          _this.spinner.hide();
        }, 5000);
        
      }
      (err) => {
        this.spinner.hide();
      }
    });
  }

  pay() {
    const accountNumber = this.accountNumber.split('|');
    this.payments1.nativeElement.attributes[1].nodeValue = 'selectpay';
    this.payments2.nativeElement.attributes[2].nodeValue = intellipay.setStoreOnly(true);intellipay.setACHAvailable(false);intellipay.setCCAvailable(true);
    this.payments3.nativeElement.attributes[3].nodeValue = 'radio';
    this.payments4.nativeElement.attributes[4].nodeValue = 'StoreOnly';
    this.payments5.nativeElement.attributes[6].nodeValue = accountNumber[0];
    this.payNowDashboard.nativeElement.click();
    this.showlink = true;
  }

  storeScheduledPayments(response){
    this.spinner.show();
    this.schedulePay.accountNumber = this.accountNumber;
    this.schedulePay.merchant.id = this.billerId;
    this.schedulePay.utility.id = this.utilityId;
    this.schedulePay.paymentSchedule = {day : null, description : 'On Bill Generate'};
    this.schedulePay.paymentType = 'Auto Payment';
    this.schedulePay.scheduleType = 'billgenerate';
    const paymentDetails = JSON.parse(response);
    this.schedulePay.transaction = {
      customerId: paymentDetails.custid,
      status: paymentDetails.status,
      paymentType: paymentDetails.paymenttype
    };
    this.paymentsService.storeMultipleSchedule(this.schedulePay).subscribe((res) => {
      if (res) {
        const scheduleJson = JSON.parse(res.result);
        scheduleJson.forEach(element => {
          this.registeredAccounts.push(element.AccountNumber);
        });
        this.accounts = this.registeredAccounts.join(',');
        const url = btoa(res.result);
        this.openHostedLink = this.backURL +'?'+  url ;
        this.spinner.hide();
      }
    });
  }

  decrypt(url: string) {
    let encryptedString = '';
   if(url.includes("?")){
    if (url.split('?')[1].includes('%3D')) {
      encryptedString = url.split('?')[1].replace('%3D', '');
    } else if (
      !url.split('?')[1].includes('%3D') &&
      url.split('?')[1].includes('=')
    ) {
      encryptedString = url.split('?')[1].replace('=', '');
    }
   }else{
    encryptedString = url.replace('=','');
   }

    return atob(decodeURIComponent(encryptedString));
  }


}

 function getQueryParams( params, url ){
   let href = url;
   let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
   let queryString = reg.exec(href);
   return queryString ? queryString[1] : null;
 };