export class BroadcastMessages {
    public id: string;
    public fromDate: string;
    public toDate: string;
    public message: string;
    public link: string;
    public document: string;
    public createdBy: string;
    public createdOn: Date;
    public modifiedBy: string;
    public modifiedOn: Date;
    public isActive: boolean;
    constructor(){
        this.id = null;
        this.fromDate = null;
        this.toDate = null;
        this.message = null;
        this.link = null;
        this.document = null;
        this.createdBy = null;
        this.createdOn = new Date;
        this.modifiedBy = null;
        this.modifiedOn = new Date;
        this.isActive = false;
    }
}
