import { Component, OnInit, OnChanges, Input, SimpleChanges, SimpleChange, AfterViewInit } from '@angular/core';
import { LatestBill } from '../_shared/model/latest-bill';
import { BroadcastMessages } from '../_shared/model/broadcast-messages';
declare var $: any;

@Component({
  selector: 'app-bill-news-ticker',
  templateUrl: './bill-news-ticker.component.html',
  styleUrls: ['./bill-news-ticker.component.css']
})
export class BillNewsTickerComponent implements OnInit, OnChanges {
  @Input('newsData') newsData: BroadcastMessages;
  @Input('billId') billId: string;
  newsList: BroadcastMessages[];
  newsId: string;
  messageList: BroadcastMessages[];

  constructor() {
    this.newsList = new Array<BroadcastMessages>();
    this.messageList = new Array<BroadcastMessages>();
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.newsData;
    this.getNewsList(data, Object(this.billId));
  }

  /* broadcast messages bind to marquee*/
  getNewsList(list, id) {
    this.newsId = id;
    this.newsList = list.currentValue;
  }

  /* if link is available in broadcast it will open in new tab*/
  viewAnotherTab(doc) {
    window.open(doc, "_blank");
  }

}
